import React from "react";
import PropTypes from "prop-types";
import { children } from "../../../../utils/propTypes";
import MUIListItem from "@material-ui/core/ListItem";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    justifyContent: "space-between",
    padding: "12px 24px",
    fontFamily: "inherit",
    opacity: 0.5,
  },
  startLabel: {
    fontSize: "1.111em",
  },
  endLabel: {
    display: "flex",
    alignItems: "center",
    fontSize: "0.88em",
    fontWeight: 600,
    color: "var(--color-grey-darker)",
  },
  icon: {
    marginLeft: 8,
  },
}));

const ListItem = ({
  isExpanded,
  hasButton,
  hasDivider,
  label,
  title,
  handleClick,
  children,
  ...props
}) => {
  const c = useStyles();

  return (
    <>
      <MUIListItem
        {...props}
        button={hasButton}
        style={{ opacity: isExpanded ? 1 : 0.5 }}
        className={c.root}
        alignItems={"center"}
        divider={hasDivider}
        onClick={handleClick}
      >
        <p className={c.startLabel}>{title}</p>

        <p className={c.endLabel}>
          {label}
          {hasButton ? (
            isExpanded ? (
              <ExpandLess className={c.icon} />
            ) : (
              <ExpandMore className={c.icon} />
            )
          ) : null}
        </p>
      </MUIListItem>

      {children}
    </>
  );
};

ListItem.propTypes = {
  hasButton: PropTypes.bool.isRequired,
  hasDivider: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool,
  handleClick: PropTypes.func,
  children,
};

export default ListItem;
