import React from "react";
import PropTypes from "prop-types";
import s from "./CoverPicture.module.css";

const Picture = ({ pictureSrc }) => {
  return <img className={s.image} src={pictureSrc} alt="Welcome to AlmaKnit" />;
};

Picture.propTypes = {
  pictureSrc: PropTypes.string.isRequired,
};

export default Picture;
