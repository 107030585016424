import { useContext, useEffect, useState } from "react";
import ApiService from "../../utils/ApiService";
import { store as modalStore } from "../../modals/SharedModal/Store";
import {
  renderNewOptions,
  getRandom,
  getModelType,
  updateInputs,
  getQueryParams,
  removeQueryParams,
  saveInputValue,
} from "./helpers";
import { Request as R } from "../../utils/helpers";
import useLocalStorage from "../useLocalStorage";
import track from "../../utils/tracking";
import { useTranslation } from "react-i18next";

import useStore from "../../store/useStore";

const useAppHandlers = () => {
  const [selectedPage, setSelectedPage] = useState(1);
  const [steps, setSteps] = useState([]);
  const [options, setOptions] = useState([]);
  const [avatarImages, setAvatarImages] = useState([]);
  const [modelTypes, setModelTypes] = useState(null);
  const [selectedModelType, setSelectedModelType] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState("cm");

  const [validatedInputs, setValidatedInputs] = useState([]);
  const [showSummary, setShowSummary] = useState(false);
  const [showEditOptionsModal, setShowEditOptionsModal] = useState(false);
  const [editingOptionKey, setEditingOptionKey] = useState(null);
  const [storedRecipeId, setStoredRecipeId] = useLocalStorage("recipeId", null);
  const [isLoading, setIsLoading] = useState(false);

  const { SetStoredRecipeId, SetInputs, inputs, ResetRecipe } = useStore();

  const setRecipeId = (id) => {
    setStoredRecipeId(id);
    SetStoredRecipeId(id);
  };

  const {
    // i18n: { language: currentLanguage },
    i18n: currenti18n,
    ready: isTranslationReady,
    t,
  } = useTranslation();
  const currentLanguage = currenti18n.language;

  useEffect(() => {
    //ability to launch app with a language set

    const { lan } = getQueryParams(window.location.href);
    if (lan) {
      //currenti18n.changeLanguage(lan);
      handleChangeLanguage(lan);
    }
  }, [currenti18n]);

  const {
    dispatch,
    state: { showModal, type: modalType },
  } = useContext(modalStore);

  const Request = R.bind({ dispatch });

  useEffect(() => {
    if (isTranslationReady && currentLanguage) {
      const { sharing, recipeId } = getQueryParams(window.location.href);

      if (sharing && recipeId) {
        var newlanguage = validateSharedRecipe(recipeId, currentLanguage);
        return;
      }

      if (storedRecipeId) {
        setIsLoading(true);
        loadRecipe(storedRecipeId, currentLanguage);

        track.user({ recipeId: storedRecipeId });
        return;
      }

      loadModelTypes(currentLanguage);
    }
  }, [isTranslationReady, currentLanguage]);

  const cloneRecipe = async (recipeId, language) => {
    const data = await Request(ApiService.CloneRecipe, recipeId, language);

    if (data) {
      if (data?.recipeId) setRecipeId(data.recipeId);
      setAllData(data);
    }
  };

  const verifyRecipe = async (setLoading) => {
    try {
      setLoading(true);

      const mappedInputs = inputs.reduce((acc, el) => {
        return [
          ...acc,
          ...el.items.map((item) => {
            return { groupKey: el.key, key: item.key, value: item.value };
          }),
        ];
      }, []);

      const data = await Request(
        ApiService.VerifyRecipe,
        storedRecipeId,
        mappedInputs
      );

      if (!data) throw "Not verified";

      if (data.selectedOptions) applyNewOptions([], [...data.selectedOptions]);
      if (data.inputs) SetInputs([...data.inputs]);

      setLoading(false);

      return true;
    } catch (err) {
      setLoading(false);

      return false;
    }
  };

  const validateSharedRecipe = async (recipeId, language) => {
    const data = await Request(ApiService.ValidateSharedRecipe, recipeId);

    if (data) {
      if (data.language) {
        language = data.language;
        handleChangeLanguage(language);
      }
      dispatch({
        type: "ShowCloneModal",
        payload: {
          handleCustomize: () => {
            cloneRecipe(recipeId, language);
            dispatch({ type: "HideModal" });
            removeQueryParams();
          },
          handleStartFromScratch: () => {
            loadModelTypes(language);
            dispatch({ type: "HideModal" });
            removeQueryParams();
          },
        },
      });
    } else {
      loadModelTypes(language);
    }
    return language;
  };

  const loadRecipe = async (recipeId, language) => {
    const data = await Request(ApiService.LoadRecipe, recipeId, language);
    if (data) {
      if (data?.recipeId) {
        setRecipeId(data?.recipeId);
      }
      if (data?.language) {
        currenti18n.changeLanguage(data?.language);
      }
      setAllData(data);
    }
    setIsLoading(false);
  };

  const resetRecipe = (modelTypes) => {
    setRecipeId(null);
    setSelectedModelType(null);
    setOptions([]);
    setModelTypes(modelTypes);
    setSelectedPage(1);
    ResetRecipe();
  };

  const setAllData = (data) => {
    const {
      status,
      modelTypes: loadedModelTypes,
      steps,
      options,
      inputs,
      avatarImages,
      selectedModelTypeKey,
    } = data;

    if (status === "resetRecipe") {
      return resetRecipe(loadedModelTypes);
    } else {
      if (steps) setSteps(steps);
      if (options) setOptions(options);
      if (inputs) SetInputs(inputs);
      if (avatarImages) setAvatarImages(avatarImages);
      if (loadedModelTypes) setModelTypes(loadedModelTypes);
      if (loadedModelTypes && selectedModelTypeKey) {
        setSelectedModelType(
          getModelType(selectedModelTypeKey, loadedModelTypes)
        );
      }
      setSelectedPage(2);
    }
  };

  const handleChangeLanguage = async (key) => {
    if (storedRecipeId) {
      const payload = { actionType: "selectLanguage", value: key };
      const response = await Request(
        ApiService.UpdateRecipe,
        storedRecipeId,
        payload
      );
      if (response && response.data) {
        setAllData(response.data);
      }
    }
    currenti18n.changeLanguage(key);
  };

  const handleChangeUnit = async (key) => {
    if (storedRecipeId) {
      const payload = {
        actionType: "updateInputs",
        value: [{ value: key, key: "measurements__unit" }],
      };
      const response = await Request(
        ApiService.UpdateRecipe,
        storedRecipeId,
        payload
      );
      if (response && response.data) {
        setAllData(response.data);
      }
    }
    setSelectedUnit(key);
  };

  const loadModelTypes = async (language) => {
    const data = await Request(ApiService.Initialize, language);
    if (data && data.hasOwnProperty("modelTypes")) {
      setModelTypes(data.modelTypes);
      track.event("Initializing new recipe", "Recipe");
    }
  };

  const selectModelType = async (modelTypeKey) => {
    if (storedRecipeId) {
      const payload = { actionType: "selectModelType", value: modelTypeKey };
      const resp = await Request(
        ApiService.UpdateRecipe,
        storedRecipeId,
        payload
      );
      if (resp) {
        return setAllData({ status: resp.status, ...resp.data });
      }
    } else {
      return createRecipe(modelTypeKey, currentLanguage);
    }
  };

  const createRecipe = async (modelTypeKey, language) => {
    const data = await Request(ApiService.CreateRecipe, modelTypeKey, language);
    if (data) {
      const { steps, options, inputs, recipeId } = data;
      setSteps(steps);
      setOptions(options);
      SetInputs(inputs ?? []);
      setRecipeId(recipeId);
      setSelectedModelType(getModelType(modelTypeKey, modelTypes));
      track.user({ recipeId: recipeId });
    }
  };

  const handleUpdateInputs = async (inputs, values) => {
    const payload = {
      actionType: "updateInputs",
      value: Array.isArray(inputs) ? inputs : [inputs],
      allValues: values,
    };
    const resp = await Request(
      ApiService.UpdateRecipe,
      storedRecipeId,
      payload
    );

    // if (data) setInputs((prev) => saveInputValue(el, prev));
    if (resp) {
      if (resp?.data?.nextAction?.inputsToUpdate) {
        applyNewInputs(resp.data.nextAction.inputsToUpdate);
      }

      const saveInputs = (updated, groupKey, inputs) => {
        const keys = updated.map((el) => el.key);
        return inputs.map((group) => {
          return group.key === groupKey
            ? {
                ...group,
                items: group.items.map((item) => {
                  return keys.includes(item.key)
                    ? {
                        ...item,
                        value: updated?.find((el) => el.key === item.key)
                          ?.value,
                      }
                    : item;
                }),
              }
            : group;
        });
      };

      const transform = Array.isArray(inputs)
        ? (_inputs, prev) => saveInputs(_inputs, "gauge", prev)
        : saveInputValue;

      SetInputs((prev) => transform(inputs, prev));
    }
  };

  const handleValidField = async (el, values) => {
    const payload = {
      actionType: "updateInputs",
      value: Array.isArray(el) ? [...el] : [el],
      allValues: values,
    };
    const resp = await Request(
      ApiService.UpdateRecipe,
      storedRecipeId,
      payload
    );

    if (resp) {
      if (resp?.data?.nextAction?.inputsToUpdate) {
        applyNewInputs(resp.data.nextAction.inputsToUpdate);
      }
      SetInputs((prev) => saveInputValue(el, prev));
    }
  };

  const handleSelectOption = (optionKey, value) => {
    handleUpdateOptions({
      currentOption: {
        optionKey,
        value,
      },
    });
  };

  const handleEditOption = ({ key, stepKey }) => {
    setShowSummary(false);
    setSelectedPage(2);
    setEditingOptionKey({
      key,
      stepKey,
      reset: () => setEditingOptionKey(null),
    });
    track.event("EditOptionFromSummary", "SummaryPage", key);
  };

  const handleSelectPage = (direction = "next") => {
    setSelectedPage((prev) => {
      return direction === "next" ? (prev < 3 ? prev + 1 : prev) : prev - 1;
    });
  };

  const handleToggleSummary = () => {
    setShowSummary((prev) => !prev);
  };

  const handleSentToCheckout = async (url) => {
    //set status to sent to Checkout
    track.page("CheckoutPage", `/checkout-page`);
    const result = await Request(ApiService.setStatusCheckout, storedRecipeId);
    if (result) window.location.href = url;
    track.event("GoToCheckout", "SummaryPage");
  };

  const handleUpdateValidatedInputs = (values) => {
    setValidatedInputs(values);
  };

  const cleanResetValue = (val) => {
    const newVal = val?.startsWith("reset")
      ? val.split("___")[1]
      : `reset${getRandom()}___${val}`;
    if (newVal === "undefined" || newVal === "null") return null;
    return newVal;
  };

  const handleRequireConfirm = (
    selectedOptions,
    optionsToReset,
    currentOption
  ) => {
    // prepending reset___ to the current option key is to force the old option to be selected in the UI, if user cancels when prompted to confirm

    const currentlySelectedOptions =
      selectedOptions ??
      options.map((el) => {
        return { optionKey: el.key, value: el.value };
      });

    const immutableOptions =
      currentlySelectedOptions &&
      currentlySelectedOptions.map((e) => {
        return e.optionKey === currentOption.currentOption.optionKey
          ? { ...e, value: cleanResetValue(e.value) }
          : e;
      });

    return dispatch({
      type: "ShowConfirmModal",
      payload: {
        options: optionsToReset,
        handleEditOption: handleEditOption,
        handleConfirmReset: () => {
          handleUpdateOptions({ ...currentOption, resetConfirmed: true });
        },
        handleCancel: () => {
          applyNewOptions([], immutableOptions);
        },
      },
    });
  };

  const handleUpdateOptions = async (currentOption) => {
    const data = await Request(
      ApiService.UpdateRecipeOptions,
      storedRecipeId,
      currentOption
    );

    if (data) {
      const { status, nextAction, selectedOptions, avatarImages } = data;

      if (status === "requireConfirm") {
        const optionsToReset = options.filter((el) =>
          nextAction.optionsToConfirm.includes(el.key)
        );

        if (optionsToReset && optionsToReset.length > 0) {
          return handleRequireConfirm(
            selectedOptions,
            optionsToReset,
            currentOption
          );
        }
      }

      if (avatarImages && avatarImages.length > 0) {
        setAvatarImages(avatarImages);
      }

      if (nextAction.hasOwnProperty("inputsToUpdate")) {
        applyNewInputs(nextAction.inputsToUpdate);
      }

      if (selectedOptions) {
        console.log("selectedOptions", selectedOptions);
        applyNewOptions(nextAction?.optionsToUpdate ?? [], selectedOptions);
      }
    }
  };

  const applyNewInputs = (inputsToUpdate) => {
    SetInputs((prev) => updateInputs(inputsToUpdate, prev));
  };

  const applyNewOptions = (optionsToUpdate, selectedOptions = []) => {
    const newOptions = renderNewOptions(
      optionsToUpdate || [],
      selectedOptions,
      options
    );
    setOptions(newOptions);
  };

  const handleInitiateRecipe = (modelTypeKey) => {
    if (modelTypeKey !== selectedModelType?.key) {
      selectModelType(modelTypeKey);
    }
    handleSelectPage("next");
  };

  const handleResetRecipe = async (skipConfirm) => {
    let confirm = true;
    if (skipConfirm !== "skipConfirm") {
      confirm = window.confirm(t("utils.confirmReset"));
    }
    if (confirm) {
      const data = await Request(ApiService.ResetRecipe, storedRecipeId);
      if (data) {
        resetRecipe(data.modelTypes);
        setAvatarImages([]); //clears avatar when new modeltype
      }
      track.event("SuccessfullyResatRecipe");
    }
  };

  return {
    isLoading,
    showSummary,
    showEditOptionsModal,
    selectedPage,
    steps,
    avatarImages,
    modelTypes,
    validatedInputs,
    options,
    selectedModelType,
    storedRecipeId,
    editingOptionKey,
    selectedUnit,
    verifyRecipe,
    setShowEditOptionsModal,
    selectModelType,
    handleResetRecipe,
    handleChangeLanguage,
    handleUpdateInputs,
    handleValidField,
    handleToggleSummary,
    handleSentToCheckout,
    handleEditOption,
    handleInitiateRecipe,
    handleSelectPage,
    handleSelectOption,
    handleUpdateOptions,
    handleUpdateValidatedInputs,
    handleChangeUnit,
  };
};

export default useAppHandlers;
