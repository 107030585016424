import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  cleanValueFromResetPrefix,
  cm2SelectedUnit,
} from "../../utils/helpers";

const useHandlers = ({ steps, inputs, options, isVisible, selectedUnit }) => {
  const [open, setOpen] = useState(false);
  const [designGroup, setDesignGroup] = useState(null);
  const [measureGroups, setMeasureGroups] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    const designGroup = {
      name: t("summary.groupNames.pattern"),
      key: "options",
      items: steps.reduce((acc, el) => {
        const filtered = options.filter(
          (o) => o.stepKey === el.key && o.visibilityType === "enabled"
        );
        return [
          ...acc,
          {
            title: el.name,
            label: `${filtered.length} ${t("utils.selections")}`,
            options: filtered.map((f) => {
              const showUnit =
                f?.props?.unit === "cm"
                  ? t("units." + selectedUnit)
                  : f?.props?.unit;
              const showValue =
                f?.props?.unit === "cm"
                  ? cm2SelectedUnit(selectedUnit, f.value)
                  : f.value;

              const labelValue =
                f.type === "swatchRadio" || f.type === "tabSelect"
                  ? f?.props?.items?.find(
                      (i) => i.key === cleanValueFromResetPrefix(f.value)
                    )?.name
                  : `${showValue} ${showUnit ?? ""}`;

              return {
                key: f.key,
                stepKey: f.stepKey,
                name: f.name,
                label: labelValue,
              };
            }),
          },
        ];
      }, []),
    };
    setDesignGroup(designGroup);
  }, [options, steps]);

  useEffect(() => {
    const _groups = inputs.map((g) => {
      console.log(g);
      return {
        key: g.key,
        name: t(`summary.groupNames.${g.key}`),
        items: g.items
          .map((el) => {
            return el.visibilityType !== "hidden" ||
              el.key === "measurements__size"
              ? {
                  title: el.label,
                  label: el.value + " " + el.unit,
                }
              : undefined;
          })
          .filter((n) => n),
      };
    });
    setMeasureGroups(_groups);
  }, [inputs]);

  useEffect(() => {
    setOpen((prev) => (prev === isVisible ? prev : isVisible));
  }, [isVisible]);

  return {
    open,
    designGroup,
    measureGroups,
  };
};

export default useHandlers;
