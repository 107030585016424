import React from "react";
import PropTypes from "prop-types";
import s from "./ButtonGroup.module.css";
import Button from "../../atoms/Button";
import { useTranslation } from "react-i18next";

const ButtonGroup = ({
  size = "medium",
  useFixedWith = true,
  secondaryProps = {},
  primaryProps = {},
  ...rest
}) => {
  const [t] = useTranslation();

  const {
    label: primaryLabel,
    testSelector = "bottomButtonNext",
    ...primaryRest
  } = primaryProps;
  const { label: secondaryLabel, ...secondaryRest } = secondaryProps;

  return (
    <div
      {...rest}
      className={`${s.buttons} ${useFixedWith ? s.fixedWidth : ""} ${
        rest.className ? rest.className : ""
      }`}
    >
      <Button size={size} type="secondary" {...secondaryRest}>
        {secondaryLabel || t(`buttons.previous`)}
      </Button>
      <Button
        data-e2e={testSelector}
        size={size}
        type="primary"
        {...primaryRest}
      >
        {primaryLabel || t(`buttons.next`)}
      </Button>
    </div>
  );
};

ButtonGroup.propTypes = {
  size: PropTypes.string,
  useFixedWith: PropTypes.bool,
  secondaryProps: PropTypes.object,
  primaryProps: PropTypes.object,
  rest: PropTypes.object,
};

export default ButtonGroup;
