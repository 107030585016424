import React, { useContext } from "react";
import styled from "styled-components";
import Conditional from "../../atoms/Conditional";
import { CircularProgress } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/HelpOutlineOutlined";
import track from "../../utils/tracking";
import { store as modalStore } from "../../modals/SharedModal/Store";
import useStore from "../../store/useStore";
import { useTranslation } from "react-i18next";

const Styled = styled.div`
  background-color: ${(p) => p.color};
  border-radius: 8px;
  padding: 12px 20px 12px 20px;
  min-height: 62px;
  text-align: left;

  & > label {
    font-weight: 500;
  }

  & .title {
    padding-top: 4px;
    display: flex;
    align-items: center;
    position: relative;

    & > .loading-indicator {
      position: absolute;
      top: 4px;
      right: 4px;
      font-size: 0.75em;
    }
  }

  & .helperIcon {
    margin-left: 8px;
    cursor: pointer;
    opacity: 0.5;
    transition: 0.1s all;
  }

  & .helperIcon:hover {
    opacity: 0.95;
  }

  & .yarnUsage-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
    font-size: 0.875em;

    & label {
      color: #9a9a9a;
      font-size: 0.88em;
    }

    & p {
      font-weight: 500;
      text-align: right;
    }
  }
`;

const YarnSpecs = ({
  items,
  children,
  showTitle = true,
  color = "#f9f9f9",
  ...props
}) => {
  const { dispatch } = useContext(modalStore);

  const { t } = useTranslation();

  const { yarnUsageSpecs, isLoadingYarnUsage } = useStore();

  const yarnUsageSpecsLocale = [];
  yarnUsageSpecs?.forEach((x) => {
    yarnUsageSpecsLocale.push({ name: t(x.prefix) + x.name, value: x.value });
  });

  const handleClick = () => {
    dispatch({
      type: "ShowDetailsModal",
      payload: {
        title: t("yarnUsage.tooltipTitle"),
        bodySrc: t("yarnUsage.yarnUsageHelperBodySrc"),
      },
    });
    track.event("ShowDetailsModalForYarnUsage", "MeasurePage", "");
  };

  return (
    <Styled {...props} color={color}>
      <Conditional when={showTitle}>
        <div className={"title"}>
          <label>{t("yarnUsage.title")}</label>
          <HelpIcon
            fontSize={"small"}
            htmlColor={"var(--color-grey-dark)"}
            onClick={handleClick}
            className={"helperIcon"}
          />
          <Conditional when={isLoadingYarnUsage}>
            <div className={"loading-indicator"}>
              <CircularProgress size={"1em"} disableShrink={true} />
            </div>
          </Conditional>
        </div>
      </Conditional>
      {(items ?? yarnUsageSpecsLocale)?.map((el, i) => {
        return (
          <div className={"yarnUsage-row"} key={`specs-${i}`}>
            <label>{el.name}</label>
            <p>{el.value}</p>
          </div>
        );
      })}

      <Conditional when={children}>{children}</Conditional>
      <Conditional when={!children && !items && !yarnUsageSpecsLocale}>
        <label>{t("yarnUsage.emptyDescription")}</label>
      </Conditional>
    </Styled>
  );
};

export default YarnSpecs;
