import { useEffect, useMemo, useState } from "react";
import track from "../../utils/tracking";

const useHandlers = ({ selectedModelType, modelTypes, defaultCoverImage }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (selectedModelType) {
      const i = modelTypes?.items.findIndex(
        (el) => el.key === selectedModelType.key
      );
      setSelectedItem({
        key: selectedModelType.key,
        index: i,
      });
    } else setSelectedItem(null);
  }, [selectedModelType, modelTypes]);

  const handleSelectItem = (key, index, handleInitiateFun) => {
    setSelectedItem({
      key,
      index,
    });
    handleInitiateFun(key);
    track.event("SelectModelType", "WelcomePage", key);
  };

  const pictureSrc = useMemo(() => {
    return selectedItem && selectedItem.index > -1
      ? modelTypes.items[selectedItem.index].coverImageSrc
      : defaultCoverImage;
  }, [selectedItem]);

  return {
    selectedItem,
    pictureSrc,
    handleSelectItem,
  };
};

export default useHandlers;
