import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import { red } from "@material-ui/core/colors";
import Button from "../../atoms/Button";
import { Launch } from "@material-ui/icons";
import YarnSpecs from "../YarnSpecs";
import Conditional from "../../atoms/Conditional";
import { Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import s from "./YarnCard.module.css";
import styled from "styled-components";
import { getMappedProps } from "../../utils/helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    maxHeight: "75vh",
    display: "flex",
    flexDirection: "column",
    marginBottom: 16,
  },
  media: {
    height: 0,
    paddingTop: "80%", // 16:9
    maxHeight: "100%",
    ["@media screen and (max-width: 699px)"]: {
      paddingTop: "50%", // 16:9
    },
  },
  content: {
    overflowY: "auto",
    flexGrow: 1,
    padding: 24,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const mapSpecs = (specs, specsMapping) => {
  return Object.entries(specs)?.reduce((acc, [key, value]) => {
    return specsMapping.hasOwnProperty(key) &&
      ((Array.isArray(value) && value.length > 0) ||
        (!Array.isArray(value) && value))
      ? [
          ...acc,
          {
            name: specsMapping[key],
            value: Array.isArray(value) ? value.join(", ") : value,
          },
        ]
      : acc;
  }, []);
};

const renderString = (value, prefix = "", suffix = "") => {
  if (value) {
    return `${prefix}${value}${suffix}`;
  }
  return "";
};

const StyledThumbnail = styled.div`
  display: flex;
  justify-content: space-evenly;
  & > div {
    height: 0;
    padding-top: ${(p) => p.height}; // 16:9
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: calc(100% / ${(el) => el.imageCount});
    @media screen and (max-width: 699px) {
      padding-top: ${(p) => p.height * 0.7};
    }
  }
`;

export const renderThumbnail = (srcs, height = "80%") => {
  return (
    <StyledThumbnail height={height} imageCount={srcs.length}>
      {srcs.map((el) => (
        <div style={{ backgroundImage: `url(${el})` }} />
      ))}
    </StyledThumbnail>
  );
};

const YarnCard = ({ title, gauge, yarns = [] }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const renderGaugeLabel = useCallback(
    (gauge) => {
      const {
        gauge__rows: rows,
        gauge__stitches: stitches,
        other__needleSize: needle,
      } = gauge ?? {};
      return `${renderString(
        stitches,
        "",
        ` ${t("yarnDrawer.yarnCard.stitchesLabel")} `
      )}${renderString(
        rows,
        "",
        ` ${t("yarnDrawer.yarnCard.gaugeLabel")}`
      )}${renderString(
        needle,
        ` ${t("yarnDrawer.yarnCard.needleLabel")} `,
        ""
      )}`;
    },
    [t]
  );

  const specsMapping = useMemo(() => {
    const keys = [
      "name",
      "brand",
      "category",
      "fibre",
      "yardage",
      "weight",
      "threadCount",
    ];

    return keys.reduce((acc, key) => {
      return {
        ...acc,
        [key]: t(`yarnDrawer.yarnCard.specsMapping.${key}`),
      };
    }, {});
  }, [t]);

  return (
    <Card raised={true} className={classes.root}>
      <CardHeader avatar={null} title={title} />
      {renderThumbnail(getMappedProps(yarns, "coverImageUrl"))}
      <CardContent className={classes.content}>
        <label>{t("yarnDrawer.yarnCard.gaugeTitle")}</label>
        <p style={{ marginBottom: 12, fontSize: "0.88em" }}>
          {renderGaugeLabel(gauge)}
        </p>

        <Conditional when={yarns.length > 1}>
          <>
            <Divider style={{ margin: "0 0 12px 0" }} />
            <label
              style={{
                display: "block",
                textAlign: "center",
                paddingBottom: 4,
              }}
            >
              {t("yarnDrawer.yarnCard.multipleYarnsPrefix")}{" "}
              <b>
                {yarns.length} {t("yarnDrawer.yarnCard.multipleYarnsSuffix")}
              </b>
            </label>
          </>
        </Conditional>

        {yarns.map(({ specs, name, buyLink }) => {
          return (
            <YarnSpecs
              style={{ margin: "6px 0" }}
              showTitle={false}
              items={mapSpecs({ name, ...specs }, specsMapping)}
            >
              <a
                href={buyLink}
                className={s.sponsoredLink}
                target={"_blank"}
                rel={"noopener"}
              >
                <Button
                  style={{ width: "100%" }}
                  label={t("buttons.buyOnline")}
                  size={"small"}
                  startIcon={<Launch />}
                />
                <label className={s.sponsoredLabel}>
                  {t("utils.sponsoredLink")}
                </label>
              </a>
            </YarnSpecs>
          );
        })}
      </CardContent>
    </Card>
  );
};

export default YarnCard;
