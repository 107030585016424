import Drawer from "@material-ui/core/Drawer";
import Button from "../../atoms/Button";
import React, { useEffect, useState } from "react";
import s from "./YarnDrawer.module.css";
import { Check, Close, Search } from "@material-ui/icons";
import { Collapse, Fade, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import YarnCard from "../YarnCard";
import Flex from "../../atoms/Flex";
import Conditional from "../../atoms/Conditional";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import YarnFilters from "../YarnFilters";
import useStore from "../../store/useStore";
import YarnSelectedCard from "../YarnSelectedCard";
import { useTranslation } from "react-i18next";
import { getMappedProps } from "../../utils/helpers";
import { renderThumbnail } from "../YarnCard";

const useStyles = makeStyles((theme) => ({
  container: {
    ["& .basic-single"]: {
      marginTop: 16,
    },
  },
}));

const YarnDrawer = ({
  GetYarns,
  SelectYarn,
  data,
  selectedYarn,
  preSelectedYarn,
  handleSelectItem,
}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [filtersIsOpen, setFiltersIsOpen] = useState(false);
  const [yarnItems, setYarnItems] = useState([]);
  const [filters, setFilters] = useState([]);
  const [sortingItems, setSortingItems] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [isReallySelected, setIsReallySelected] = useState(true);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState({});
  const classes = useStyles();

  const { SetSelectedYarn: _SetSelectedYarn } = useStore();

  const SetSelectedYarn = SelectYarn ?? _SetSelectedYarn;

  const isMobile = useMediaQuery("(max-width:699px)");

  useEffect(() => {
    if (data) {
      const { filters, selectYarn: _selectedYarn, sorting, items } = data;
      if (filters) setFilters(filters);
      if (sorting) setSortingItems(sorting);
      if (items) setYarnItems(items);
      if (_selectedYarn) {
        const selectedIndex = items.findIndex(
          (el) => el.key === _selectedYarn.key
        );
        setSelected(selectedIndex);
        setIsReallySelected(true);
        SetSelectedYarn({ ..._selectedYarn }, false);
      }
    }
  }, [data]);

  const handleChangeFilters = (items, key) => {
    if (key) {
      setSelectedFilterOptions((prev) => {
        const obj = {
          ...prev,
          [key]: items,
        };
        if (items.length === 0) delete obj[key];
        return { ...obj };
      });
    }
  };

  const makeFilterSearchObject = (filters) => {
    return Object.entries(filters).reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: value.map((el) => el.value),
      };
    }, {});
  };

  const handleApplyFilters = () => {
    const obj = makeFilterSearchObject(selectedFilterOptions);
    GetYarns({ filters: { ...obj }, includeFilters: false });
    handleToggleFilters();
  };

  const handleConfirmSelect = () => {
    setIsReallySelected(true);
    setShowPreview(false);
    setIsOpen(false);
    SetSelectedYarn({ ...(yarnItems?.[selected] ?? {}) }).then(() => {
      handleSelectItem?.({ ...(yarnItems?.[selected]?.prefilled ?? {}) });
    });
  };

  useEffect(() => {
    if (preSelectedYarn && yarnItems?.length > 0) {
      const index = yarnItems.findIndex((el) => el.key === preSelectedYarn);
      if (index !== null && index > -1) {
        setSelected(index);
        setIsReallySelected(true);
      }
    }
  }, [preSelectedYarn, yarnItems]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  const handleSelect = (index) => () => {
    //handleSelectItem(index);
    setSelected(index);
    setShowPreview(true);
  };

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
    setFiltersIsOpen(false);
    setShowPreview(true);
  };

  const handleClearFilters = () => {
    setSelectedFilterOptions({});
  };

  const handleToggleFilters = () => setFiltersIsOpen((prev) => !prev);

  const handleClearSelected = () => {
    setIsReallySelected(false);
    SetSelectedYarn();
  };

  const handleCloseCollapses = () => {
    setShowPreview(false);
    setFiltersIsOpen(false);
  };

  return (
    <>
      {selectedYarn === null || !isReallySelected ? (
        <Button
          label={t("yarnDrawer.buttons.selectYarn")}
          type={"secondary"}
          handleClick={toggleDrawer(true)}
        />
      ) : (
        <YarnSelectedCard
          imageUrls={getMappedProps(selectedYarn?.yarns, "coverImageUrl")}
          title={selectedYarn?.name}
          urls={getMappedProps(selectedYarn?.yarns, ["buyLink", "name"])}
          count={selectedYarn?.yarns?.length}
          handleClearSelected={handleClearSelected}
          handleEditClick={handleToggle}
        />
      )}

      <div className={s.divider}>
        <span>{t("pages.measure.gaugeFormDividerLabel")}</span>
      </div>

      <Drawer anchor={"right"} open={isOpen} onClose={toggleDrawer(false)}>
        <div className={s.drawerContent}>
          <div className={s.content}>
            <Fade in={filtersIsOpen || showPreview}>
              <div onClick={handleCloseCollapses} className={s.overlay} />
            </Fade>
            <Flex alignX={"space-between"}>
              <h3>{t("yarnDrawer.title")}</h3>
              <Conditional when={isMobile}>
                <IconButton onClick={toggleDrawer(false)}>
                  <Close />
                </IconButton>
              </Conditional>
            </Flex>

            <p>{t("yarnDrawer.description")}</p>
            <label className={s.disclaimer}>{t("yarnDrawer.disclaimer")}</label>

            <div className={s.items}>
              {yarnItems.map((el, i) => {
                return (
                  <div className={s.item} onClick={handleSelect(i)}>
                    {renderThumbnail(
                      getMappedProps(el?.yarns, "coverImageUrl"),
                      "100%"
                    )}
                    <p>
                      {el?.name}
                      {" - "}
                      {el?.brand}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={s.bottom}>
            {showPreview && (
              <IconButton
                onClick={handleCloseCollapses}
                style={{ marginBottom: 8 }}
              >
                <div className={s.closeIcon} />
              </IconButton>
            )}
            <section className={showPreview ? s.previewMode : ""}>
              <Collapse in={showPreview} className={"collapse"}>
                <YarnCard
                  yarns={
                    showPreview
                      ? yarnItems[selected]?.yarns
                      : selectedYarn?.yarns
                  }
                  gauge={yarnItems[selected]?.prefilled}
                  title={
                    showPreview ? yarnItems[selected]?.name : selectedYarn?.name
                  }
                />
              </Collapse>
              <Collapse
                classes={classes}
                in={filtersIsOpen}
                className={"collapse"}
              >
                <YarnFilters
                  handleChangeFilter={handleChangeFilters}
                  handleClearFilters={handleClearFilters}
                  selectedFilterOptions={selectedFilterOptions}
                  filters={filters}
                />
              </Collapse>
              <Button
                type={showPreview || filtersIsOpen ? "primary" : "secondary"}
                fullwidth
                style={{ minHeight: 40 }}
                label={
                  showPreview
                    ? t("yarnDrawer.buttons.useForRecipe")
                    : filtersIsOpen
                    ? t("yarnDrawer.buttons.applyFilters")
                    : t("yarnDrawer.buttons.search")
                }
                handleClick={
                  showPreview
                    ? handleConfirmSelect
                    : filtersIsOpen
                    ? handleApplyFilters
                    : handleToggleFilters
                }
                startIcon={
                  showPreview || filtersIsOpen ? <Check /> : <Search />
                }
              />
            </section>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default YarnDrawer;
