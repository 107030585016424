import React from "react";
import s from "./ShareLink.module.css";
import IconButton from "@material-ui/core/IconButton";
import { FileCopy } from "@material-ui/icons";

const CopyLink = ({
  handleClick,
  buttonLabel,
  url = "https://app.almaknit.com?sharing=true&recipeId=123465",
  ...rest
}) => {
  return (
    <div className={s.container} {...rest}>
      <p>{url}</p>

      <IconButton onClick={handleClick} color={"primary"} size={"small"}>
        <FileCopy />
      </IconButton>
    </div>
  );
};

export default CopyLink;
