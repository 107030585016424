import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import copy from "copy-to-clipboard";
import ApiService from "../../../utils/ApiService";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { store as modalStore } from "../Store";
import { store as snackbarStore } from "../../../components/Snackbar/Store";
import { Request as R } from "../../../utils/helpers";
import track from "../../../utils/tracking";
import { validate } from "../../../utils/helpers";

const getShareUrl = (recipeId) => {
  return new URL(
    `?sharing=true&recipeId=${recipeId}`,
    `${window.location.protocol}//${window.location.host}`
  ).toString();
};

const useHandlers = () => {
  const { t } = useTranslation();

  const { dispatch } = useContext(modalStore);
  const { snackbar } = useContext(snackbarStore);

  const Request = R.bind({ dispatch });

  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [storeRecipeId] = useLocalStorage("recipeId", null);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (storeRecipeId) {
      Request(ApiService.ShareLink, storeRecipeId).then((data) => {
        if (data && data.recipeId) {
          setUrl(getShareUrl(data.recipeId));
        }
      });
      // setUrl(getShareUrl(storeRecipeId));
    }
  }, [storeRecipeId]);

  const emailSchema = yup
    .string()
    .email(t("utils.errors.invalidEmail"))
    .nullable();

  const handleCopyLink = () => {
    if (!storeRecipeId) {
      return snackbar({
        isVisible: true,
        message: t("modals.share.snackbar.noRecipe"),
        severity: "warning",
      });
    }

    copy(url);

    snackbar({
      isVisible: true,
      message: t("modals.share.snackbar.copied"),
      severity: "success",
    });
    track.event("CopyShareLink", "ShareModal");
  };

  const handleChange = (evt) => {
    const { value, id } = evt.target;

    if (id === "message") return setMessage(value);

    validate(value, emailSchema)
      .then((email) => {
        setEmail(email);
        if (email) {
          setIsEmailValid(true);
        }
      })
      .catch(() => setIsEmailValid(false));
  };

  const handleSubmit = async () => {
    if (!storeRecipeId) {
      return snackbar({
        isVisible: true,
        message: t("modals.share.snackbar.noRecipe"),
        severity: "warning",
      });
    }

    try {
      await validate(email, emailSchema, setError, setIsEmailValid);

      const payload = { email, message };

      const data = await Request(
        ApiService.ShareByEmail,
        payload,
        storeRecipeId
      );

      if (data) {
        snackbar({
          isVisible: true,
          message: t("modals.share.snackbar.emailSent"),
          severity: "success",
        });

        setEmail(null);

        dispatch({
          type: "HideModal",
        });

        track.event("SuccessfullySharedRecipe", "Header", storeRecipeId);
      }
    } catch (err) {}
  };

  return {
    url,
    isEmailValid,
    error,
    handleCopyLink,
    handleChange,
    handleSubmit,
  };
};

export default useHandlers;
