export default [
  {
    src:
      "https://almaknit-images.s3.eu-north-1.amazonaws.com/app/modularDrawing/upperbody/female/female_avatar.png",
    zIndex: 1,
  },
  {
    src:
      "https://almaknit-images.s3.eu-north-1.amazonaws.com/app/modularDrawing/upperbody/female/female_yoke-saddle.png",
    zIndex: 2,
  },
  {
    src:
      "https://almaknit-images.s3.eu-north-1.amazonaws.com/app/modularDrawing/upperbody/female/female_neckline-vNeck_openingDepth-deep.png",
    zIndex: 4,
  },
  {
    src:
      "https://almaknit-images.s3.eu-north-1.amazonaws.com/app/modularDrawing/upperbody/female/female_neckline-vNeck_openingDepth-deep_collar-ribFolded_width-4.png",
    zIndex: 5,
  },
  {
    src:
      "https://almaknit-images.s3.eu-north-1.amazonaws.com/app/modularDrawing/upperbody/female/female_sleeve-balloon_sleeveEnding-rib_width-8.png",
    zIndex: 2,
  },
  {
    src:
      "https://almaknit-images.s3.eu-north-1.amazonaws.com/app/modularDrawing/upperbody/female/female_body-straight_ease-10.png",
    zIndex: 2,
  },
  {
    src:
      "https://almaknit-images.s3.eu-north-1.amazonaws.com/app/modularDrawing/upperbody/female/female_body-straight_ease-10_bodyending-rib_width-5.png",
    zIndex: 3,
  },
];
