import { useMemo, useState } from "react";
import track from "../../utils/tracking";
import useStore from "../../store/useStore";

const defaultGuideImageSrc = process.env.REACT_APP_DEFAULT_GUIDE_IMAGE_URL;

const useHandlers = ({
  inputs,
  steps,
  handleInitSummary,
  handleUpdateValidatedInputs,
}) => {
  const [selectedStep, setSelectedStep] = useState(0);
  const [selectedImageSrc, setSelectedImageSrc] = useState(
    defaultGuideImageSrc
  );
  const [isLoading, setIsLoading] = useState(null);

  const { isFormValid, SetIsFormValid } = useStore();

  const handleSelectStep = (index) => setSelectedStep(index);

  const filteredInputs = inputs.reduce((acc, el) => {
    return steps?.[selectedStep]?.key === el.key
      ? [
          ...acc,
          {
            ...el,
            items: el.items.filter((i) => i.visibilityType === "enabled"),
          },
        ]
      : acc;
  }, []);

  const handleSelectImage = (url) => {
    setSelectedImageSrc(url);
  };

  const handleValidForm = (values) => {
    SetIsFormValid(true);
    handleUpdateValidatedInputs(values);
  };

  const handleInvalidateForm = () => SetIsFormValid(false);

  const handleVerify = () => {
    handleInitSummary(setIsLoading);
    track.event("ClickVerifyRecipe", "MeasurePage");
  };

  return {
    isLoading,
    isFormValid,
    selectedImageSrc,
    filteredInputs,
    selectedStep,
    handleSelectStep,
    handleVerify,
    handleInvalidateForm,
    handleValidForm,
    handleSelectImage,
  };
};

export default useHandlers;
