import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import s from "./styles.module.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import StepElement from "../../atoms/StepElement";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Fab from "../../atoms/Fab";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    zIndex: 5,
  },
});

const StepsMobile = forwardRef(
  (
    { refs, steps, handleFabNext, isNextDisabled = true, handleFabPrevious },
    wrapperRef
  ) => {
    const classes = useStyles();

    return (
      <div className={s.container}>
        {
          <Fab
            size={"small"}
            style={{ left: 8 }}
            classes={classes}
            onClick={handleFabPrevious}
          >
            <ArrowBackIcon htmlColor={"var(--color-main)"} />
          </Fab>
        }

        <div className={s.steps} ref={wrapperRef}>
          {steps.map((el, index) => {
            return <StepElement isMobile={true} ref={refs[index]} {...el} />;
          })}
        </div>

        <Fab
          disabled={isNextDisabled}
          classes={classes}
          style={{ right: 8, backgroundColor: "var(--color-main)" }}
          size={"small"}
          onClick={handleFabNext}
        >
          <ArrowForwardIcon htmlColor={"white"} />
        </Fab>
      </div>
    );
  }
);

StepsMobile.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      isSelected: PropTypes.bool.isRequired,
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      handleClick: PropTypes.func,
    })
  ).isRequired,
  refs: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleFabNext: PropTypes.func.isRequired,
  handleFabPrevious: PropTypes.func.isRequired,
  isNextDisabled: PropTypes.bool,
};

export default StepsMobile;
