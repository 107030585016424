import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import s from "./StepElement.module.css";
import CheckmarkAnimation from "../CheckmarkAnimation";

const StepElement = forwardRef(
  (
    {
      index,
      label,
      name,
      isSelected = false,
      isFulfilled = false,
      hasNudge,
      handleClick,
      isMobile = false,
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        className={`${s.wrapper} ${isSelected ? s.selected : ""} ${
          isFulfilled ? s.fulfilled : ""
        } ${hasNudge ? s.hasNudge : ""} ${isMobile ? s.isMobile : ""}`}
        onClick={handleClick}
      >
        <div className={s.statusIcon}>
          {isFulfilled && <CheckmarkAnimation />}
        </div>
        <p className={isSelected ? s.selected : null}>{name}</p>
        <h4 className={isSelected ? s.selected : null}>{label}</h4>
      </div>
    );
  }
);

StepElement.propTypes = {
  name: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  isFulfilled: PropTypes.bool,
  isMobile: PropTypes.bool,
  hasNudge: PropTypes.bool,
  label: PropTypes.string,
};

export default StepElement;
