import React, { useContext } from "react";
import PropTypes from "prop-types";
import { helperLabel, details } from "../../utils/propTypes";
import s from "./Option.module.css";
import { store as modalStore } from "../../modals/SharedModal/Store";

import HelpIcon from "@material-ui/icons/HelpOutlineOutlined";
import OptionTitle from "../../atoms/OptionTitle";
import track from "../../utils/tracking";

const HelperLabel = ({ type, text }) => {
  return (
    <div className={`${s.label} ${type === "error" ? s.error : ""}`}>
      {text}
    </div>
  );
};

const Option = ({
  title,
  children,
  className,
  helperLabel,
  details = null,
  testSelector = null,
}) => {
  const { dispatch } = useContext(modalStore);

  const handleDetailsClick = () => {
    dispatch({
      type: "ShowDetailsModal",
      payload: details,
    });
    track.event("ShowDetailsModalForOption", "DesignPage", title);
  };

  return (
    <div data-e2e={testSelector} className={`${s.wrapper} ${className}`}>
      <div className={s.heading}>
        <div className={s.title}>
          <OptionTitle title={title} />
          {details && (
            <HelpIcon
              htmlColor={"var(--color-grey-dark)"}
              onClick={handleDetailsClick}
              className={s.helperIcon}
            />
          )}
        </div>
        {helperLabel && helperLabel.text && <HelperLabel {...helperLabel} />}
      </div>

      {children}
    </div>
  );
};

Option.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  testSelector: PropTypes.string,
  helperLabel,
  details,
};

export default Option;
