import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { isMobile } from "react-device-detect";

import s from "./OptionsSkeleton.module.css";

const OptionsSkeleton = () => {
  return (
    <div className={s.container}>
      <Skeleton animation="wave" width={"70%"} />

      <div className={s.items}>
        {[1, 2, 3].map((el) => {
          return (
            <div className={s.item} key={el}>
              <Skeleton
                style={{
                  borderRadius: 8,
                }}
                variant="rect"
                height={isMobile ? 80 : 120}
                width={isMobile ? 80 : 120}
                animation={"pulse"}
              />

              <Skeleton animation="wave" width={80} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OptionsSkeleton;
