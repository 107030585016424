import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { children } from "../../utils/propTypes";

const ZoomIn = ({ children, delayFactor }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => setEnabled(true), []);

  return (
    <div
      style={{
        opacity: enabled ? 1 : 0,
        transform: enabled ? "scale(1)" : "scale(0.8)",
        transition: "0.2s all",
        transitionDelay: `${delayFactor * 40 + 40}ms `,
      }}
    >
      {children}
    </div>
  );
};

ZoomIn.propTypes = {
  delayFactor: PropTypes.number,
  children,
};

export default ZoomIn;
