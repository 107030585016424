import React from "react";
import PropTypes from "prop-types";
import s from "./Clone.module.css";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ModalContent from "../../Wrapper/ModelContent";
import { useTranslation } from "react-i18next";
import ButtonGroup from "../../../molecules/ButtonGroup";

const Clone = ({ handleStartFromScratch, handleCustomize }) => {
  const { t } = useTranslation();

  return (
    <ModalContent>
      <div className={s.wrapper}>
        <div className={s.icon}>
          <CheckCircleIcon
            htmlColor={"var(--color-green)"}
            fontSize={"inherit"}
          />
        </div>

        <h2>{t("modals.clone.title")}</h2>

        <p>{t("modals.clone.description")}</p>

        <ButtonGroup
          size={"small"}
          useFixedWith={false}
          primaryProps={{
            label: t("modals.clone.actions.customize"),
            handleClick: handleCustomize,
          }}
          secondaryProps={{
            label: t("modals.clone.actions.fromScratch"),
            handleClick: handleStartFromScratch,
          }}
        />
      </div>
    </ModalContent>
  );
};

Clone.propTypes = {
  handleStartFromScratch: PropTypes.func,
  handleCustomize: PropTypes.func,
};

export default Clone;
