import React from "react";
import s from "./Header.module.css";
import Button from "../../../atoms/Button";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import LanguageSelect from "../../../molecules/LanguageSelect";
import { getQueryParams } from "../../../hooks/useAppHandlers/helpers";

const logoUrl = process.env.REACT_APP_LOGO_URL;

const languages = [
  {
    key: "da",
    emoji: "🇩🇰",
  },
  {
    key: "en",
    emoji: "🇬🇧",
  },
];

const Header = () => {
  const [_, setStoredRecipeId] = useLocalStorage("recipeId", null);

  const [isLanguageSelectOpen, setLanguageSelectOpen] = React.useState(false);

  const { i18n, t } = useTranslation();

  const handleStartNewRecipe = () => {
    setStoredRecipeId(null);
    window.open("/?lan=" + i18n.language, "_self");
  };

  React.useEffect(() => {
    //ability to launch app with a language set
    const { lan } = getQueryParams(window.location.href);
    if (lan) {
      i18n.changeLanguage(lan);
    }
  }, [i18n]);

  const handleLanguageSelectClose = (key) => {
    setLanguageSelectOpen(false);
    return i18n.changeLanguage(key);
  };

  const handleOpenLanguageSelect = () => {
    setLanguageSelectOpen(true);
  };

  return (
    <header className={s.header}>
      <div className={`${s.showBackground} ${s.wrapper}`}>
        <a href={"https://almaknit.com"}>
          <img className={s.logo} src={logoUrl} alt={"AlmaKnit Designer"} />
        </a>

        <div className={s.right}>
          {
            <LanguageSelect
              selectedLanguage={i18n.language}
              isOpen={isLanguageSelectOpen}
              onClose={handleLanguageSelectClose}
              languages={languages}
            />
          }
          <Button
            type={"secondary"}
            label={t("recalibrate.designnew")}
            size={"small"}
            handleClick={handleStartNewRecipe}
          />
          <Tooltip title={t("menuItems.selectLanguage")}>
            <IconButton
              className={s.emojiSelect}
              onClick={handleOpenLanguageSelect}
            >
              <p>{languages.find((el) => el.key === i18n.language)?.emoji}</p>
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </header>
  );
};

export default React.memo(Header);
