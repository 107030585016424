export async function Request(method, ...params) {
  try {
    const data = await method(...params);
    if (data instanceof Error) throw JSON.parse(data.message);
    return data;
  } catch (err) {
    const obj =
      typeof err === "string"
        ? { title: "Something went wrong", msg: err }
        : err;
    this?.dispatch?.({
      type: "ShowErrorModal",
      payload: obj,
    });
  }
}

export const cleanValueFromResetPrefix = (val) =>
  val?.startsWith("reset") ? val.split("___")[1] : val;

export const validate = async (value, schema, setError, setIsValid) => {
  try {
    await schema.validate(value);
    return Promise.resolve(value);
  } catch (err) {
    if (setError) setError(err.message);
    if (setIsValid) setIsValid(false);
    return Promise.reject(err);
  }
};

export const createYarnUsageObject = (meters = 0, yarns) => {
  return yarns.reduce(
    (acc, { name, ballsOfYarn }) => {
      return [
        ...acc,
        { prefix: "yarnUsage.noSkeins", name: name, value: ballsOfYarn },
      ];
    },
    [{ prefix: "yarnUsage.estimatedYarn", name: "", value: meters }]
  );
};

export const getMappedProps = (arr, props) => {
  if (!arr?.length > 0) return [];
  if (typeof props === "string") return arr.map((el) => el[props]);
  if (Array.isArray(props) && props.length === 1)
    return arr.map((el) => el[props[0]]);
  return arr.map((el) => {
    let out = {};
    for (const p of props) {
      out[p] = el[p];
    }
    return out;
  });
};

export const cm2SelectedUnit = (selectedUnit, value) => {
  return selectedUnit === "inches"
    ? Math.round((value / 2.54) * 10) / 10
    : value;
};
