import React from "react";
import PropTypes from "prop-types";
import { modelTypeObj } from "../../utils/propTypes";
import { useTranslation } from "react-i18next";
import Component from "./WelcomePage";
import CoverPicture from "./CoverPicture";
import Swatch from "../../molecules/Swatch";
import Option from "../../molecules/Option";
import OptionsSkeleton from "../../molecules/Skeletons/OptionsSkeleton";
import HeadingSkeleton from "../../molecules/Skeletons/Heading";
import { useMediaQuery } from "@material-ui/core";
import useHandlers from "./useHandlers";
import usePageview from "../../hooks/usePageview";

const defaultCoverImage = process.env.REACT_APP_DEFAULT_COVER_IMAGE_URL;

const WelcomePage = ({
  isHidden,
  handleInitiateRecipe,
  modelTypes,
  selectedModelType,
}) => {
  const { t, ready: isTranslationReady } = useTranslation();

  const isMobile = useMediaQuery("(max-width: 699px)");

  usePageview("WelcomePage");

  const { selectedItem, handleSelectItem, pictureSrc } = useHandlers({
    selectedModelType,
    modelTypes,
    defaultCoverImage,
  });

  return (
    <Component
      {...{
        isHidden: isHidden,
        title: isTranslationReady ? (
          t("pages.welcome.title")
        ) : (
          <HeadingSkeleton />
        ),
        description: isTranslationReady ? t("pages.welcome.description") : "",
        contentProps: {
          left: isMobile ? null : <CoverPicture pictureSrc={pictureSrc} />,
          right: modelTypes ? (
            <Option
              testSelector={"modelTypes"}
              title={modelTypes.name}
              helperLabel={modelTypes.helperLabel}
            >
              <Swatch
                data-tour={"swatch"}
                noWrap={true}
                items={modelTypes.items}
                handleClick={(key, index) =>
                  handleSelectItem(key, index, handleInitiateRecipe)
                }
                selectedItemKey={selectedItem?.key}
              />
            </Option>
          ) : (
            <OptionsSkeleton />
          ),
        },
        buttonProps: {
          isDisabled: selectedItem === null,
          label: t("buttons.getStarted"),
          handleClick: () => handleInitiateRecipe(selectedItem.key),
        },
      }}
    />
  );
};

WelcomePage.propTypes = {
  isHidden: PropTypes.bool.isRequired,
  handleInitiateRecipe: PropTypes.func.isRequired,
  modelTypes: PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["swatchRadio", "tabSelect"]).isRequired,
    items: PropTypes.arrayOf(modelTypeObj),
  }),
  selectedModelType: modelTypeObj,
};

export default WelcomePage;
