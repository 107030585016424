import { useEffect, useRef, createRef } from "react";

const useHandlers = ({ selectedStep, steps }) => {
  const wrapperRef = useRef(null);

  const refs = steps.map(() => createRef());

  useEffect(() => {
    if (refs.length === 0 || !refs[selectedStep].current) return;

    const wrapperWidth = wrapperRef.current.clientWidth;

    const getScrollLeft = (step) => {
      if (step > 0) {
        return (
          refs[step].current.offsetLeft -
          (wrapperWidth - refs[step].current.clientWidth) / 2
        );
      } else return 0;
    };

    wrapperRef.current.scrollTo({
      left: getScrollLeft(selectedStep),
      behavior: "smooth",
    });
  }, [selectedStep]);

  return {
    refs,
    wrapperRef,
  };
};

export default useHandlers;
