import React from "react";
import PropTypes from "prop-types";
import Thumbnail from "../../atoms/Thumbnail";
import OptionTitle from "../../atoms/OptionTitle";
import s from "./RowItem.module.css";
import EditIcon from "@material-ui/icons/Edit";
import Button from "../../atoms/Button";

const RowItem = ({
  handleClick,
  allowEdit = false,
  imageSrc,
  title,
  label,
  editButtonLabel = "Rediger",
  children,
  hasLinethrough = true,
  ...props
}) => {
  return (
    <div {...props} className={s.item}>
      <div className={s.first}>
        {typeof imageSrc === "string" ? (
          <Thumbnail imageSrc={imageSrc} size={"medium"} isDisabled={true} />
        ) : (
          imageSrc
        )}

        <div className={s.itemContent}>
          {children ? (
            children
          ) : (
            <>
              <OptionTitle
                title={title}
                style={{
                  textDecoration: hasLinethrough ? "line-through" : "initial",
                }}
              />

              <p
                style={{
                  textDecoration: hasLinethrough ? "line-through" : "initial",
                }}
              >
                {label}
              </p>
            </>
          )}
        </div>
      </div>
      {allowEdit && (
        <Button size={"small"} handleClick={handleClick}>
          <EditIcon style={{ marginRight: 4 }} fontSize={"inherit"} />
          {editButtonLabel}
        </Button>
      )}
    </div>
  );
};

RowItem.propTypes = {
  title: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,

  allowEdit: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default RowItem;
