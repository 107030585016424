import React from "react";
import PropTypes from "prop-types";
import s from "./OptionTitle.module.css";

const OptionTitle = ({ title, children, ...rest }) => (
  <>
    <h3 {...rest} className={s.title}>
      {title}
    </h3>
    {children}
  </>
);

OptionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  rest: PropTypes.object,
};

export default OptionTitle;
