import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { children } from "../../utils/propTypes";
import s from "./Carousel.module.css";
import Dots from "../../molecules/Dots";

import useCarouselHandlers from "./useCarouselHandlers";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { useMediaQuery } from "@material-ui/core";

const Carousel = ({
  children,
  shouldScrollToTop,
  handleNextPage,
  handlePreviousPage,
  isLastStep,
  shouldSetCurrentItemTo,
  shouldPreventGoToNextOption,
  setShouldPreventGoToNextOption,
}) => {
  const isMobile = useMediaQuery("(max-width:699px)");

  const {
    currentItem,
    wrapperHandlers,
    refs,
    contentRef,
    selectDot,
    setShouldGoToNextOption,
  } = useCarouselHandlers({
    children,
    shouldScrollToTop,
    handleNextPage,
    handlePreviousPage,
    shouldSetCurrentItemTo,
    isLastStep,
    setShouldPreventGoToNextOption,
    shouldPreventGoToNextOption,
  });

  const handleClick = (evt, index) => {
    if (currentItem !== index) {
      selectDot(index);
    }
  };

  const dotItems = useMemo(() => {
    return React.Children.map(children, (el) => {
      return {
        isFulfilled: el.props.isFulfilled,
        visibilityType: el.props.visibilityType,
      };
    });
  }, [children]);

  const clickHandler = (handler, key) => {
    if (!isLastStep) setShouldGoToNextOption(true);
    handler(key);
  };

  const getHandler = (el) => {
    if (el.props.optionType === "slider") {
      return {
        handleUpdate: (key) =>
          clickHandler(
            React.Children.only(el.props.children).props.handleUpdate,
            key
          ),
      };
    } else {
      return {
        handleClick: (key) =>
          clickHandler(
            React.Children.only(el.props.children).props.handleClick,
            key
          ),
      };
    }
  };

  const hideNextArrow = isLastStep && currentItem + 1 === dotItems.length;

  return (
    <>
      <div className={s.wrapper} {...wrapperHandlers}>
        <div
          data-e2e={"carouselContent"}
          className={s.content}
          ref={contentRef}
        >
          {React.Children.map(children, (el, i) => {
            return (
              <div
                onClick={(evt) => handleClick(evt, i)}
                data-fulfilled={el.props.isFulfilled}
                data-type={el.props.optionType}
                className={`${s.item} ${currentItem !== i ? s.opaque : ""} ${
                  s[el.props.visibilityType]
                }`}
                ref={refs[i].ref}
              >
                {React.Children.count(el.props?.children) === 1
                  ? React.cloneElement(el, {
                      children: React.cloneElement(
                        React.Children.only(el.props.children),
                        getHandler(el)
                      ),
                    })
                  : el}
              </div>
            );
          })}

          <div style={{ height: 500 }} />
        </div>

        {currentItem > 0 && (
          <div
            className={s.previousArea}
            onClick={() => selectDot(currentItem - 1)}
          >
            <ArrowUpward />
          </div>
        )}

        {!hideNextArrow && (
          <div
            className={s.nextArea}
            onClick={() => selectDot(currentItem + 1)}
          >
            <ArrowDownward />
          </div>
        )}
      </div>

      <Dots
        className={s.dots}
        setShouldPreventGoToNextOption={setShouldPreventGoToNextOption}
        items={dotItems}
        hideNextArrow={hideNextArrow}
        selectedIndex={currentItem}
        handleClick={selectDot}
        showButtons={dotItems.length > 1 && !isMobile}
      />
    </>
  );
};

Carousel.propTypes = {
  children,
  shouldScrollToTop: PropTypes.oneOfType([
    PropTypes.string, // can be null
    PropTypes.number,
  ]),
};

export default Carousel;
