import React from "react";
import PropTypes from "prop-types";
import { options } from "../../utils/propTypes";
import Option from "../../molecules/Option";
import styles from "./Options.module.css";
import { components } from "./componentMapping";
import Carousel from "../Carousel";
import OptionsSkeleton from "../../molecules/Skeletons/OptionsSkeleton";
import useHandlers from "./useHandlers";
import { useTranslation } from "react-i18next";

const Options = ({
  options,
  currentStepKey,
  handleSelectOption,
  editingOptionFromSummary,
  handleNextPage,
  isLastStep,
  handlePreviousPage,
  shouldPreventGoToNextOption,
  setShouldPreventGoToNextOption,
  selectedUnit,
}) => {
  const { filtered, currentOption } = useHandlers({
    options,
    currentStepKey,
    editingOptionFromSummary,
  });

  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Carousel
        isLastStep={isLastStep}
        shouldSetCurrentItemTo={currentOption}
        shouldScrollToTop={currentStepKey}
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
        shouldPreventGoToNextOption={shouldPreventGoToNextOption}
        setShouldPreventGoToNextOption={setShouldPreventGoToNextOption}
      >
        {filtered.length > 0 ? (
          filtered.map((el) => {
            console.log(el);
            const Component = components[el.type]["comp"];
            const compProps = components[el.type]["props"](
              el,
              handleSelectOption
            );

            const showUnit =
              el.props.unit === "cm"
                ? t("units." + selectedUnit)
                : el.props.unit;

            return (
              <Option
                testSelector={`designOption`}
                key={el.key}
                className={styles.option}
                title={el.props.unit ? `${el.name} (${showUnit})` : el.name}
                optionType={el.type}
                helperLabel={el.helperLabel}
                details={el.details}
                visibilityType={el.visibilityType}
                isFulfilled={Boolean(el.value)}
              >
                <Component selectedUnit={selectedUnit} {...compProps} />
              </Option>
            );
          })
        ) : (
          <OptionsSkeleton />
        )}
      </Carousel>
    </div>
  );
};

Options.propTypes = {
  currentStepKey: PropTypes.string,
  handleSelectOption: PropTypes.func.isRequired,
  options,
};

export default Options;
