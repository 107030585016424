import React from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Dialog from "@material-ui/core/Dialog";

import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: "rgba(200,200,200,0.3)",
  },
  selected: {
    border: "2px solid var(--color-main)",
    backgroundColor: "rgba(200,200,200,0.3)",
  },
  item: {
    opacity: 0.7,
    ["&:hover"]: {
      opacity: 1,
    },
  },
  selectedItem: {
    opacity: 1,
  },
});

const useDialogClasses = makeStyles({
  paper: {
    minWidth: 240,
    ["& p"]: {
      fontSize: "0.9em",
    },
  },
});

const LanguageSelect = ({ onClose, selectedLanguage, isOpen, languages }) => {
  const classes = useStyles();
  const c = useDialogClasses();
  const { t } = useTranslation();

  const handleClose = () => {
    onClose(selectedLanguage);
  };

  const handleListItemClick = (key) => {
    onClose(key);
  };

  return (
    <Dialog onClose={handleClose} open={isOpen} classes={c}>
      <List>
        {languages.map((lang) => (
          <ListItem
            button
            className={
              selectedLanguage === lang.key
                ? classes.selectedItem
                : classes.item
            }
            onClick={() => handleListItemClick(lang.key)}
            key={lang.key}
          >
            <ListItemAvatar>
              <Avatar
                className={`${
                  selectedLanguage === lang.key
                    ? classes.selected
                    : classes.avatar
                }`}
              >
                {lang.emoji}
              </Avatar>
            </ListItemAvatar>
            <p>{t(`locales.${lang.key}`)}</p>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

LanguageSelect.propTypes = {
  onClose: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      emoji: PropTypes.string.isRequired,
    })
  ),
};

export default LanguageSelect;
