import { useContext, useState } from "react";
import { store as modalStore } from "../../modals/SharedModal/Store";
import track from "../../utils/tracking";

const useHandlers = ({
  i18n,
  handleChangeLanguage,
  handleResetRecipe,
  handleChangeUnit,
}) => {
  const { dispatch } = useContext(modalStore);

  const [selectedLanguage, setSelectedLanguage] = useState("da");
  const [isLanguageSelectOpen, setLanguageSelectOpen] = useState(false);
  const [isUnitSelectOpen, setUnitSelectOpen] = useState(false);

  i18n.on("languageChanged", setSelectedLanguage);

  const handleLanguageSelectClose = (key) => {
    setLanguageSelectOpen(false);
    setSelectedLanguage(key);
    handleChangeLanguage(key);
    return i18n.changeLanguage(key);
  };

  const handleUnitSelectClose = (key) => {
    setUnitSelectOpen(false);
    handleChangeUnit(key);
  };

  const handleOpenLanguageSelect = () => {
    setLanguageSelectOpen(true);
    track.event("ClickOpenLanguageSelect", "Header");
  };

  const handleOpenUnitSelect = () => {
    setUnitSelectOpen(true);
  };

  const handleSelectMenuItem = (key) => {
    if (key === "selectLanguage") {
      setLanguageSelectOpen(true);
      track.event("ClickOpenLanguageSelect", "HeaderMenu");
    }

    if (key === "selectUnit") {
      setUnitSelectOpen(true);
    }

    if (key === "resetRecipe") {
      handleResetRecipe();
      track.event("ClickResetRecipeFromHeaderMenu", "HeaderMenu");
    }

    if (key === "shareRecipe") {
      dispatch({
        type: "ShowShareModal",
      });
      track.event("ClickShareRecipe", "HeaderMenu");
    }

    if (key === "yarnUsage") {
      dispatch({
        type: "ShowYarnUsageModal",
      });
      track.event("ClickOpenYarnUsageModal", "HeaderMenu");
    }
  };

  const handleLogoClick = () => {
    // console.log("logo clicked");
    window.location.href =
      "https://almaknit.com" + (selectedLanguage === "en" ? "/en" : "");
  };

  return {
    selectedLanguage,
    isLanguageSelectOpen,
    isUnitSelectOpen,
    handleLogoClick,
    handleSelectMenuItem,
    handleOpenLanguageSelect,
    handleOpenUnitSelect,
    handleLanguageSelectClose,
    handleUnitSelectClose,
  };
};

export default useHandlers;
