import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import ApiService from "../../../utils/ApiService";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { store as modalStore } from "../Store";
import { Request as R } from "../../../utils/helpers";

const validate = async (val, schema, setError, setIsFormValid) => {
  try {
    await schema.validate(val);
    return Promise.resolve(val);
  } catch (err) {
    if (setError) setError(err.message);
    if (setIsFormValid) setIsFormValid(false);
    return Promise.reject();
  }
};

const useHandlers = () => {
  const { t } = useTranslation();

  const { dispatch } = useContext(modalStore);

  const Request = R.bind({ dispatch });

  const initialState = {
    gauge: null,
    chest: null,
    yarnMeters: null,
    balloonSleeves: false,
    rollNeck: false,
  };

  const initialResult = {
    yarnUsage: null,
    ballsOfYarn: null,
  };

  const [formState, setFormState] = useState(initialState);
  const [errorGauge, setErrorGauge] = useState(null);
  const [errorChest, setErrorChest] = useState(null);
  const [errorYarnMeters, setErrorYarnMeters] = useState(null);
  const [errorForm, setErrorForm] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [yarnUsageResult, setYarnUsageResult] = useState(initialResult);

  const gaugeSchema = yup
    .number()
    .required()
    .typeError(t("modals.yarnUsage.error.gaugeTypeError"))
    .min(10, t("modals.yarnUsage.error.gaugeMin") + 10)
    .max(40, t("modals.yarnUsage.error.gaugeMax") + 40);

  const chestSchema = yup
    .number()
    .typeError(t("modals.yarnUsage.error.chestTypeError"))
    .required()
    .min(55, t("modals.yarnUsage.error.chestMin") + 55)
    .max(140, t("modals.yarnUsage.error.chestMax") + 140);

  const balloonSleevesSchema = yup.boolean();

  const rollNeckSchema = yup.boolean();

  // helper for yup transform function
  function emptyStringToNull(value, originalValue) {
    if (typeof originalValue === "string" && originalValue === "") {
      return null;
    }
    return value;
  }
  const yarnMetersSchema = yup
    .number()
    .typeError(t("modals.yarnUsage.error.yarnMetersTypeError"))
    .min(50, t("modals.yarnUsage.error.yarnMetersMin"))
    .max(300, t("modals.yarnUsage.error.yarnMetersMax"))
    .transform(emptyStringToNull)
    .nullable();

  const validationSchema = yup.object().shape({
    gauge: gaugeSchema,
    chest: chestSchema,
    balloonSleeves: balloonSleevesSchema,
    rollNeck: rollNeckSchema,
    yarnMeters: yarnMetersSchema,
  });

  const error = {
    gauge: errorGauge,
    chest: errorChest,
    yarnMeters: errorYarnMeters,
  };

  const validateForm = async () => {
    try {
      await validate(formState, validationSchema, setErrorForm, setIsFormValid);
      setIsFormValid(true);
    } catch (err) {
      setIsFormValid(false);
    }
  };

  useEffect(() => {
    validateForm()
      .then((x) => console.log(x))
      .catch((x) => console.log(x));
  });

  const handleChange = (evt) => {
    const { value, id, checked } = evt.target;

    if (id === "gauge") {
      validate(value, gaugeSchema, setErrorGauge, setIsFormValid)
        .then((gauge) => {
          setFormState((prevState) => {
            return {
              ...prevState,
              gauge: gauge,
            };
          });
          setErrorGauge(false);
        })
        .catch((err) => setIsFormValid(false));
    }

    if (id === "chest") {
      validate(value, chestSchema, setErrorChest, setIsFormValid)
        .then((chest) => {
          setFormState((prevState) => {
            return {
              ...prevState,
              chest: chest,
            };
          });
          setErrorChest(false);
        })
        .catch((err) => setIsFormValid(false));
    }

    if (id === "yarnMeters") {
      validate(value, yarnMetersSchema, setErrorYarnMeters, setIsFormValid)
        .then((yarnMeters) => {
          setFormState((prevState) => {
            return {
              ...prevState,
              yarnMeters: yarnMeters,
            };
          });
          setErrorYarnMeters(false);
        })
        .catch((err) => {
          setIsFormValid(false);
        });
    }

    if (id === "balloonSleeves") {
      validate(checked, balloonSleevesSchema)
        .then((balloonSleeves) => {
          setFormState((prevState) => {
            return {
              ...prevState,
              balloonSleeves: balloonSleeves,
            };
          });
        })
        .catch((err) => setIsFormValid(false));
    }

    if (id === "rollNeck") {
      validate(checked, rollNeckSchema)
        .then((rollNeck) => {
          setFormState((prevState) => {
            return {
              ...prevState,
              rollNeck: rollNeck,
            };
          });
        })
        .catch((err) => setIsFormValid(false));
    }
  };

  const handleSubmit = async () => {
    try {
      await validate(formState, validationSchema, setErrorForm, setIsFormValid);

      const data = await Request(ApiService.GetYarnUsage, {
        formState: formState,
      });

      if (data) {
        if (data.yarnUsage) {
          setYarnUsageResult((prevState) => {
            return {
              ...prevState,
              yarnUsage: data.yarnUsage,
            };
          });
        } else {
          setYarnUsageResult((prevState) => {
            return {
              ...prevState,
              yarnUsage: null,
            };
          });
        }

        if (data.ballsOfYarn) {
          setYarnUsageResult((prevState) => {
            return {
              ...prevState,
              ballsOfYarn: data.ballsOfYarn,
            };
          });
        } else {
          setYarnUsageResult((prevState) => {
            return {
              ...prevState,
              ballsOfYarn: null,
            };
          });
        }
      }
    } catch (err) {}
  };

  return {
    formState,
    isFormValid: isFormValid && !errorYarnMeters,
    error,
    yarnUsageResult,
    handleChange,
    handleSubmit,
  };
};

export default useHandlers;
