import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { inputs } from "../../utils/propTypes";
import useHandlers from "./useHandlers";
import s from "./MeasureForm.module.css";
import useStore from "../../store/useStore";
import isEqual from "lodash.isequal";
import Option from "../../molecules/Option";
import YarnDrawer from "../YarnDrawer";
import Input from "../../atoms/Input";
import Conditional from "../../atoms/Conditional";
import YarnSpecs from "../YarnSpecs";
import { useFetch } from "../../hooks/useFetch";
import ApiService from "../../utils/ApiService";
import useLocalStorage from "../../hooks/useLocalStorage";

const Presets = ({ items, selected, handleSelect }) => {
  return (
    <div className={s.presets}>
      {items.map((el, i) => {
        return (
          <div
            onClick={() => handleSelect(i)}
            key={el.key}
            className={selected === i && s.selected}
          >
            {el.label}
          </div>
        );
      })}
    </div>
  );
};

const MeasureForm = ({
  inputs,
  handleValidField,
  handleValidForm,
  handleInvalidateForm,
  handleSelectImage,
}) => {
  //14.10.23: moved up  from below usehandlers so RefreshYarnUsageFromRecipe can be given as input
  const {
    selectedYarn,
    storedRecipeId,
    isFormStateGauge,
    RefreshYarnUsageFromRecipe,
  } = useStore();

  //14.10.23: add RefreshYarnUsageFromRecipe as input to usehandlers
  const {
    inputGroups,
    values,
    touched,
    errors,
    handleChange,
    handleCustomBlur: handleBlur,
    setValues,
  } = useHandlers({
    inputs,
    handleValidForm,
    handleInvalidateForm,
    handleValidField,
    RefreshYarnUsageFromRecipe,
  });

  const { data, execHandler } = useFetch((payload) =>
    ApiService.GetYarns(storedRecipeId, { ...payload, includeFilters: true })
  );

  const handleFocusInput = (inputKey, imageSrc) => {
    handleSelectImage(imageSrc);
  };

  useEffect(() => {
    if (inputGroups?.[0]?.defaultGuideImage) {
      handleSelectImage(inputGroups[0].defaultGuideImage);
    }
  }, [isFormStateGauge]);

  const [selectedPreset, setSelectedPreset] = useState(-1);

  const { ApplyPresetOrYarn } = useStore();

  const handleSelectPreset = (index) => {
    setSelectedPreset(index);
    const _values = presets?.[index]?.values;
    if (_values) {
      setValues(_values);
      ApplyPresetOrYarn(_values, "measurements");
    }
  };

  const handleSelectYarn = (props) => {
    setValues(props);
    ApplyPresetOrYarn(props, "gauge");
  };

  const presets = useMemo(() => {
    return (
      inputGroups.find((el) => el.hasOwnProperty("presets"))?.presets ?? []
    );
  }, [inputGroups]);

  useEffect(() => {
    let match = -1;
    for (let i = 0; i < presets.length; i++) {
      if (isEqual(presets?.[i]?.values ?? {}, values)) {
        match = i;
        break;
      }
    }
    setSelectedPreset(match);
  }, [values, presets]);

  return (
    <form data-e2e={"measureForm"} className={s.form}>
      {inputGroups.map((i, index) => {
        return (
          <div className={s.inputSection} key={`inputGroup-${index}`}>
            <Option
              title={i.name}
              helperLabel={{
                type: "info",
                text: i.helperLabel,
              }}
              details={i.details}
            >
              {i.key === "measurements" && (
                <Presets
                  items={i.presets}
                  selected={selectedPreset}
                  handleSelect={handleSelectPreset}
                />
              )}

              {i.key === "gauge" && (
                <YarnDrawer
                  selectedYarn={selectedYarn}
                  handleSelectItem={handleSelectYarn}
                  GetYarns={execHandler}
                  data={data}
                />
              )}
            </Option>

            <div data-e2e={"inputGroup"} className={s.items}>
              {i.items.map((el, pos) => {
                return el.htmlType === "hidden" ? (
                  <></>
                ) : (
                  <Input
                    isSelect={el.isSelect}
                    unit={el.unit}
                    data-testValue={el.testValue}
                    key={`input-${el.key}`}
                    name={el.key}
                    type={"text"}
                    label={el.label}
                    color={"primary"}
                    value={values[el.key] || ""}
                    onChange={handleChange}
                    onBlur={(e) => {
                      handleBlur(e, {
                        groupKey: i.key,
                        key: el.key,
                        value: e.target.value,
                      });
                    }}
                    onFocus={() => handleFocusInput(el.key, el.guideImageSrc)}
                    error={touched[el.key] && Boolean(errors[el.key])}
                    helperText={
                      (touched[el.key] && errors[el.key]) || el.helperLabel
                    }
                  />
                );
              })}
            </div>

            <Conditional when={i.key === "gauge"}>
              <YarnSpecs className={s.yarnSpecs} style={{ width: "100%" }} />
            </Conditional>
          </div>
        );
      })}
    </form>
  );
};

MeasureForm.propTypes = {
  handleValidField: PropTypes.func.isRequired,
  handleValidForm: PropTypes.func.isRequired,
  handleInvalidateForm: PropTypes.func.isRequired,
  handleSelectImage: PropTypes.func.isRequired,
  inputs,
};

export default MeasureForm;
