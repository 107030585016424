import * as yup from "yup";


function gaugeStitches(params, msg) {
  if (params) {
    params = JSON.parse(params)

    var ref = yup.ref(params.ref);
    var minRatio = params.minRatio;
    var maxRatio = params.maxRatio;

    return this.test({
      name: 'gaugeStitches',
      exclusive: false,
      message: msg || '${path} is not a valid gauge.',
      params: {
        reference: ref.path
      },
      test: function (value) {
        if (this.resolve(ref)) {
          return value <= maxRatio * this.resolve(ref) && value > minRatio * this.resolve(ref)
        } else {
          return true
        }
      }
    })
  } else {
    return this.test({
      name: 'gaugeRows',
      exclusive: false,
      message: msg || '${path} is not a valid gauge.',
      params: {

      },
      test: function (value) {
        return true
      }
    })
  }
};

function gaugeRows(params, msg) {

  if (params) {

    params = JSON.parse(params)
    var ref = yup.ref(params.ref);
    var minRatio = params.minRatio;
    var maxRatio = params.maxRatio;

    return this.test({
      name: 'gaugeRows',
      exclusive: false,
      message: msg || '${path} is not a valid gauge.',
      params: {
        reference: ref.path
      },
      test: function (value) {
        if (this.resolve(ref)) {
          return value < 1 / minRatio * this.resolve(ref) && value >= 1 / maxRatio * this.resolve(ref);
        } else {
          return true
        }
      }
    })
  } else {
    return this.test({
      name: 'gaugeRows',
      exclusive: false,
      message: msg || '${path} is not a valid gauge.',
      params: {

      },
      test: function (value) {
        return true
      }
    })
  }
};

yup.addMethod(yup.number, 'gaugeStitches', gaugeStitches);
yup.addMethod(yup.number, 'gaugeRows', gaugeRows);

export const createYupSchema = (schema, config) => {
  const { key: id, validationType, validations = [] } = config;
  if (!yup[validationType]) {
    return schema;
  }
  let validator = yup[validationType]();
  validations.forEach((validation) => {
    const { params, type } = validation;
    if (!validator[type]) {
      return;
    }
    validator = validator[type](...params);
  });
  schema[id] = validator;
  return schema;
};

export const isEmpty = (obj) => {
  if (obj == null) return true;

  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  if (typeof obj !== "object") return true;

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
  }

  return true;
};

export const flattenSchemas = (inputs) => {
  return inputs.reduce((acc, group) => {
    return [...acc, ...group.items];
  }, []);
};

export const extractInitials = (inputs) => {
  return flattenSchemas(inputs).reduce((acc, el) => {
    return el.hasOwnProperty("value") ? { ...acc, [el.key]: el.value } : acc;
  }, {});
};
