import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { actions } from "../../../utils/propTypes";
import ApiService from "../../../utils/ApiService";
import CircularProgress from "@material-ui/core/CircularProgress";
import ModalHeader from "../../Wrapper/ModalHeader";
import ModalContent from "../../Wrapper/ModelContent";
import ModalActions from "../../Wrapper/ModalActions";

const Spinner = () => {
  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <CircularProgress />
    </div>
  );
};

const Details = ({ bodySrc, title, actions }) => {
  const [body, setBody] = useState(null);

  useEffect(() => {
    ApiService.GetModalContent(bodySrc).then(setBody);
  }, []);

  return (
    <>
      <ModalHeader {...title} />

      <ModalContent>
        {body ? (
          <div dangerouslySetInnerHTML={{ __html: body }} />
        ) : (
          <Spinner />
        )}
      </ModalContent>

      {actions && <ModalActions items={actions} />}
    </>
  );
};

Details.propTypes = {
  bodySrc: PropTypes.string.isRequired,
  title: PropTypes.shape({
    text: PropTypes.string,
    transKey: PropTypes.string,
  }),
  actions,
};

export default Details;
