import React from "react";
import PropTypes from "prop-types";
import { Button as MB } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = {
  root: {
    marginLeft: 8,
    color: "white",
  },
};

const StyledButton = withStyles({
  root: {
    fontSize: "0.88em",
    padding: "10px 16px",
    fontWeight: "bold",
    letterSpacing: 0.5,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  },
  sizeSmall: {
    fontSize: "0.75em",
    padding: "6px 10px",
    minWidth: "auto !important",
    paddingLeft: 16,
    paddingRight: 16,
  },
  outlined: {
    minWidth: 200,
    ["@media screen and (max-width: 699px)"]: {
      // eslint-disable-line no-useless-computed-key
      minWidth: "initial",
      padding: "6px 10px",
      fontSize: "0.7em",
    },
  },
  contained: {
    minWidth: 200,
    ["@media screen and (max-width: 699px)"]: {
      // eslint-disable-line no-useless-computed-key
      minWidth: "initial",
      padding: "6px 10px",
      fontSize: "0.7em",
    },
  },
  label: {
    textTransform: "uppercase",
  },
})(MB);

const types = {
  primary: "contained",
  secondary: "outlined",
};

const SpinnerAdornment = withStyles(styles)((props) => {
  const color = props.type === "primary" ? "var(--color-main)" : "#fff";
  return (
    <CircularProgress
      style={{ margin: "0 4px" }}
      color={color}
      size={18}
      thickness={5}
    />
  );
});

function Button({
  handleClick,
  children,
  type,
  size = "medium",
  isDisabled = false,
  isLoading = false,
  label,
  ...props
}) {
  return (
    <StyledButton
      color={"primary"}
      {...props}
      size={size}
      variant={types[type]}
      onClick={handleClick}
      disabled={isDisabled}
      endIcon={isLoading && <SpinnerAdornment type={type} />}
    >
      {label || children}
    </StyledButton>
  );
}

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  label: PropTypes.string,
  isDisabled: PropTypes.bool,
  type: PropTypes.oneOf(["primary", "secondary"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  handleClick: PropTypes.func,
  rest: PropTypes.object,
};

export default Button;
