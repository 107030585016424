import React from "react";
import PropTypes from "prop-types";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import BackIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import s from "./SummaryAppBar.module.css";
import Button from "../../../atoms/Button";
import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "white",
    color: "var(--color-grey-darker)",
    boxShadow: "0 2px 12px 0 #E6E6E6",
    border: "1px solid #E6E6E6",
    ["@media screen and (max-width: 699px)"]: {
      position: "fixed",
      top: 0,
      bottom: "auto",
    },
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    ["@media screen and (max-width: 699px)"]: {
      display: "none",
    },
  },
}));

const SummaryAppBar = ({
  title,
  handleClose,
  priceLabel,
  url,
  handleSentToCheckout,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <IconButton edge="start" color="inherit" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          {title || t("summary.headerTitle")}
        </Typography>

        <div className={s.price}>
          <p>{t("summary.totalPrice")}</p>
          <p>
            <strong>{priceLabel}</strong>
          </p>
        </div>
        <a
          data-e2e={"checkoutButton"}
          // href={url}
          // rel="noopener noreferrer"
          // target={"_top"}
          onClick={() => handleSentToCheckout(url)}
        >
          <Button color="primary" type={"primary"} size={"medium"}>
            {t("summary.checkoutButtonLabel")}
          </Button>
        </a>
      </Toolbar>
    </AppBar>
  );
};

SummaryAppBar.propTypes = {
  priceLabel: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSentToCheckout: PropTypes.func.isRequired,
  url: PropTypes.string,
};

export default SummaryAppBar;
