import React from "react";
import PropTypes from "prop-types";
import s from "./Thumbnail.module.css";

const Thumbnail = ({
  size = "large",
  imageSrc,
  isSelected,
  isDisabled = false,
}) => {
  return (
    <div
      className={`${s.container} ${isSelected ? s.selected : null} ${s[size]} ${
        isDisabled ? s.disabled : null
      }`}
      style={{ backgroundImage: `url('${imageSrc}')` }}
    />
  );
};

Thumbnail.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium", "large"]),
};

export default Thumbnail;
