// @flow

import type {
  InitializeResp,
  LoadRecipeResp,
  CreateRecipeResp,
  UpdateOptionsResp,
  UpdateRecipeResp,
  ShareByEmailResp,
  ValidateSharedRecipeResp,
  CloneRecipeResp,
  VerifyRecipeResp,
  ResetRecipeResp,
  ShareLinkResp,
  GetInputsResp,
  GetYarnsResp,
  GetYarnUsageResp,
  GetYarnUsageForRecipeResp,
  SelectYarnResp,
  FindRecipeResp,
  Error,
} from "./types";

export const baseUrl = process.env.REACT_APP_API_URL || "http://localhost:8000";

export class ApiService {
  static async BaseRequest(url: string, opts: any = {}): any {
    try {
      const res: Response = await fetch(url, opts);
      const contentType: ?string = res.headers.get("content-type");
      const data =
        contentType && contentType.includes("application/json")
          ? await res.json()
          : await res.text();
      if (!res.ok) throw data;
      if (data.hasOwnProperty("status") && data.status === "error")
        throw data.error;
      return data;
    } catch (err) {
      return new Error(JSON.stringify(err));
    }
  }

  static async FetchData(url: string, payload: any): any {
    if (payload && typeof payload === "object") {
      return ApiService.BaseRequest(`${url}`, {
        method: "POST",
        body: JSON.stringify({ ...payload }),
        headers: {
          "Content-Type": "application/json",
        },
      });
    } else {
      return ApiService.BaseRequest(url);
    }
  }

  Initialize(language: string): Promise<InitializeResp> {
    return ApiService.BaseRequest(
      `${baseUrl}/recipe/initialize?language=${language}`
    );
  }

  LoadRecipe(
    storedRecipeId: string,
    language: string
  ): Promise<LoadRecipeResp> {
    return ApiService.BaseRequest(
      `${baseUrl}/recipe/${storedRecipeId}/load?language=${language}`
    );
  }

  GetInputs(storedRecipeId: string): Promise<GetInputsResp> {
    return ApiService.BaseRequest(`${baseUrl}/recipe/${storedRecipeId}/inputs`);
  }

  CreateRecipe(
    modelTypeKey: string,
    language: string
  ): Promise<CreateRecipeResp> {
    return ApiService.BaseRequest(
      `${baseUrl}/recipe/create?language=${language}`,
      {
        method: "POST",
        body: JSON.stringify({ modelTypeKey }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  test() {
    return {
      actionType: "updateInputs",
      value: [
        {
          key: "gauge__sticthes",
          value: 21,
        },
        {
          key: "gauge__needle",
          value: 21,
        },
        {
          key: "gauge__rows",
          value: 21,
        },
      ],
    };
  }

  UpdateRecipe(
    recipeId: string,
    data: {
      actionType: "selectLanguage" | "selectModelType" | "updateInputs",
      value: string | Array<any | { [string]: number }>,
    }
  ): Promise<UpdateRecipeResp> {
    return ApiService.BaseRequest(`${baseUrl}/recipe/${recipeId}/update`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  UpdateRecipeOptions(
    recipeId: string,
    payload: {
      currentOption: {
        optionKey: string,
        value: string | number,
      },
      resetConfirmed?: boolean, // will be true if user confirms dialog to reset other options
    }
  ): Promise<UpdateOptionsResp> {
    return ApiService.BaseRequest(
      `${baseUrl}/recipe/${recipeId}/update/options`,
      {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  GetModalContent(url: string): Promise<string> {
    return ApiService.BaseRequest(url);
  }

  ShareByEmail(
    payload: Object<{ email: string, message: string }>,
    recipeId: string
  ): Promise<ShareByEmailResp> {
    return ApiService.BaseRequest(`${baseUrl}/recipe/${recipeId}/share/email`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  ValidateSharedRecipe(recipeId: string): Promise<ValidateSharedRecipeResp> {
    return ApiService.BaseRequest(`${baseUrl}/recipe/${recipeId}/validate`, {
      method: "POST",
      body: JSON.stringify({
        recipeId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  CloneRecipe(recipeId: string, language: string): Promise<CloneRecipeResp> {
    return ApiService.BaseRequest(
      `${baseUrl}/recipe/${recipeId}/clone?language=${language}`,
      {
        method: "POST",
        body: JSON.stringify({ recipeId }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  VerifyRecipe(
    recipeId: string,
    inputs: Array<{
      key: string,
      groupKey: string,
      value: string | number,
    }>
  ): Promise<VerifyRecipeResp> {
    return ApiService.BaseRequest(`${baseUrl}/recipe/${recipeId}/verify`, {
      method: "POST",
      body: JSON.stringify({ inputs }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  ResetRecipe(recipeId: string): Promise<ResetRecipeResp> {
    return ApiService.BaseRequest(`${baseUrl}/recipe/${recipeId}/reset`, {
      method: "POST",
    });
  }

  ShareLink(recipeId: string): Promise<ShareLinkResp> {
    return ApiService.BaseRequest(`${baseUrl}/recipe/${recipeId}/share/link`);
  }

  setStatusCheckout(recipeId: string): Promise<SetStatusCheckoutResp> {
    console.log(
      "running setStatusCheckout for returning `${baseUrl}/recipe/${recipeId}/setStatusCheckout` for BaseRequest"
    );
    return ApiService.BaseRequest(
      `${baseUrl}/recipe/${recipeId}/setStatusCheckout`
    );
  }

  GetYarnUsage(payload: { formState: Object }): Promise<GetYarnUsageResp> {
    return ApiService.BaseRequest(`${baseUrl}/other/yarnUsage`, {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  GetYarns(
    storedRecipeId?: string,
    payload: {
      filters?: {
        [string]: string | Array<string | number>,
      },
      sorting?: [string, number], // first is key of selected sorting, last is direction => 1 = Ascending, -1 = Descending
      includeFilters?: boolean, // whether to return filters and sortings in response, default to false
    }
  ): Promise<GetYarnsResp> {
    return ApiService.BaseRequest(`${baseUrl}/yarns`, {
      method: "POST",
      body: JSON.stringify({ payload, storedRecipeId }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  GetYarnUsageForRecipe(
    storedRecipeId: string,
    payload: { selectedYarnKey: string }
  ): Promise<GetYarnUsageForRecipeResp> {
    return ApiService.BaseRequest(
      `${baseUrl}/recipe/${storedRecipeId}/yarnUsage`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  GetYarnUsageForRecalibrate(payload: {
    storedRecipeId: string,
    inputs: {
      [string]: string, // key is reused
    },
    selectedYarnKey?: string,
  }): Promise<GetYarnUsageForRecipeResp> {
    return ApiService.BaseRequest(`${baseUrl}/recalibrate/yarnUsage`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  SelectYarn(
    storedRecipeId: string,
    selectedYarnKey: string
  ): Promise<SelectYarnResp> {
    return ApiService.BaseRequest(
      `${baseUrl}/recipe/${storedRecipeId}/selectYarn`,
      {
        method: "POST",
        body: JSON.stringify({ selectedYarnKey }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  FindRecipe(
    payload: {
      email: string,
      orderNumber: string,
    },
    language: string
  ): Promise<FindRecipeResp> {
    return ApiService.BaseRequest(
      `${baseUrl}/recipe/find?language=${language}`,
      {
        method: "POST",
        body: JSON.stringify({ ...payload }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  SubmitRecalibrateRecipe(payload: {
    email: string,
    recipeId: string,
    orderNumber: number,
    inputs: {
      [string]: string,
    },
    selectedYarnKey?: string,
  }): Promise<{
    status: "success" | "error",
    error?: Error,
  }> {
    return ApiService.BaseRequest(`${baseUrl}/recalibrate/submit`, {
      method: "POST",
      body: JSON.stringify({ ...payload }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

const api: ApiService = new ApiService();

export default api;
