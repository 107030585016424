import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, makeStyles } from "@material-ui/core";
import Input from "../../atoms/Input";
import Button from "../../atoms/Button";
import useCookie from "../../hooks/useCookie";

const domainsToAuthenticate = process.env.REACT_APP_DOMAINS_TO_AUTH?.split(
  ","
) ?? ["fiddlery", "localhost", "molly-almaknit-test"];
const password = process.env.REACT_APP_FIDDLERY_PASSWORD || "123456";

const useStyles = makeStyles(() => ({
  root: {
    padding: 24,
  },
}));

const LoginDialog = () => {
  const c = useStyles();

  const [isAuthenticated, setIsAuthenticated] = useCookie(
    "AlmaKnitFiddleryAuthenticated"
  );

  const [shouldAuthenticate, setShouldAuthenticate] = useState(false);
  const [typedPassword, setTypedPassword] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    const { host } = window.location;
    setShouldAuthenticate(() =>
      domainsToAuthenticate.some((el) => host.includes(el))
    );
  }, []);

  const handleUpdate = (evt) => {
    const { value } = evt.target;
    setTypedPassword(value);
  };

  const handleSubmit = () => {
    setIsAuthenticated(() => {
      if (typedPassword.trim() === password) {
        setError(false);
        return true;
      } else {
        setError(true);
        return false;
      }
    });
  };

  return (
    <Dialog open={!isAuthenticated && shouldAuthenticate}>
      <DialogContent classes={c}>
        <Input
          fullWidth
          type={"string"}
          spellCheck={false}
          name={"password"}
          label={"Kodeord"}
          autoFocus
          onChange={handleUpdate}
          error={error}
          helperText={"Forkert kodeord, prøv igen."}
        />

        <Button
          style={{ marginTop: 8 }}
          label={"Log ind"}
          fullWidth
          type={"primary"}
          isDisabled={!typedPassword || typedPassword.length < 5}
          handleClick={handleSubmit}
        />
      </DialogContent>
    </Dialog>
  );
};

export default LoginDialog;
