import React from "react";
import PropTypes from "prop-types";
import { items } from "../../../utils/propTypes";
import { useTranslation } from "react-i18next";
import SummaryList from "../SummaryList";
import s from "./SummaryContent.module.css";
import YarnSelectedCard from "../../../components/YarnSelectedCard";
import YarnSpecs from "../../../components/YarnSpecs";
import Conditional from "../../../atoms/Conditional";
import { getMappedProps } from "../../../utils/helpers";

const SummaryContent = ({ title, groups, selectedYarn, handleEditOption }) => {
  const { t } = useTranslation();

  return (
    <div className={s.container}>
      <div className={s.heading}>
        <h4>{title || t("summary.contentTitle")}</h4>
        <p>{t("summary.contentDescription")}</p>
      </div>

      {groups.map((el, i) => {
        return (
          <SummaryList
            groupKey={el.key}
            key={`summaryList-${i}`}
            headline={el.name}
            items={el.items}
            handleSelectOption={handleEditOption}
          />
        );
      })}

      <div className={s.heading}>
        <h4>{t("summary.selectedYarnTitle")}</h4>
        <p>{t("summary.selectedYarnDescription")}</p>
      </div>

      <Conditional when={Boolean(selectedYarn)}>
        <YarnSelectedCard
          color={"white"}
          imageUrls={getMappedProps(selectedYarn?.yarns, "coverImageUrl")}
          title={selectedYarn?.name}
          urls={getMappedProps(selectedYarn?.yarns, ["buyLink", "name"])}
          count={selectedYarn?.yarns?.length}
        />
      </Conditional>

      <YarnSpecs color={"white"} style={{ width: "100%" }} />
    </div>
  );
};

SummaryContent.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      items: items,
    })
  ),
  handleEditOption: PropTypes.func,
};

export default SummaryContent;
