import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import { makeStyles } from "@material-ui/core/styles";

import { isMobile } from "react-device-detect";

import useSelectItem from "../../hooks/useSelectItem";

const useStyles = makeStyles({
  buttonGroup: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    ["@media screen and (max-width: 699px)"]: {
      maxWidth: "90%",
    },
  },
  button: {
    borderRightColor: "initial !important",
    ["@media screen and (max-width: 699px)"]: {
      margin: "2px !important",
      borderRadius: "4px !important",
    },
  },
  selected: {
    backgroundColor: "var(--color-main) !important",
    color: "white !important",
    ["&:hover"]: {
      backgroundColor: "var(--color-main) !important",
    },
  },
});

const TabsSelect = ({ items, selectedItemKey, handleClick, ...rest }) => {
  const classes = useStyles();

  const { selectItem, selected } = useSelectItem({
    selectedItemKey,
    handleClick,
  });

  return (
    <ButtonGroup
      {...rest}
      className={classes.buttonGroup}
      color="primary"
      size={isMobile ? "small" : "medium"}
      data-e2e={"tabSelect"}
    >
      {items.map((el, i) => {
        return (
          <Button
            key={`tab-button-${i}`}
            disableElevation
            className={`${classes.button} ${
              el.key === selected ? classes.selected : ""
            }`}
            onClick={() => selectItem(el.key, i)}
          >
            {el.name}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

TabsSelect.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  selectedItemKey: PropTypes.string,
  handleClick: PropTypes.func,
};

export default TabsSelect;
