import React, { useContext } from "react";
import PropTypes from "prop-types";
import { options } from "../../../utils/propTypes";
import RowItem from "../../../molecules/RowItem";
import ModalContent from "../../Wrapper/ModelContent";
import ModalHeader from "../../Wrapper/ModalHeader";
import ModalActions from "../../Wrapper/ModalActions";
import { useTranslation } from "react-i18next";
import sliderIcon from "./sliderIcon.svg";
import tabSelectIcon from "./tabSelectIcon.svg";
import s from "./Confirm.module.css";
import { store } from "../Store";

const getValue = (option) => {
  if (option.type === "slider") {
    return `${cleanValue(option.value)} ${option.props.unit}`;
  }
  const found = getItem(option);
  return (found && found.name) || "";
};

const cleanValue = (value) => {
  if (value && value.includes && value.includes("___")) {
    return value.split("___")[1];
  }
  return value;
};

const getItem = (option) => {
  return option.props.items.find((i) => i.key === cleanValue(option.value));
};

const getIcon = (option) => {
  const { type } = option;
  if (type === "slider") return sliderIcon;
  if (type === "tabSelect") return tabSelectIcon;
  return getItem(option)?.thumbnailSrc;
};

const Confirm = ({
  options = [],
  handleEditOption,
  handleConfirmReset,
  handleCancel,
}) => {
  const { t } = useTranslation();

  const { dispatch } = useContext(store);

  return (
    <>
      <ModalHeader
        disableClose={true}
        transKey={"modals.confirmOptionReset.title"}
      />

      <ModalContent className={s.content}>
        {options?.map((el, i) => {
          return (
            <RowItem
              key={`option-item-${i}`}
              imageSrc={getIcon(el)}
              title={el.name}
              allowEdit={false}
              handleClick={() =>
                handleEditOption({ key: el.key, stepKey: el.stepKey })
              }
              label={
                <>
                  {t("utils.yourChoice")}: <strong>{getValue(el)}</strong>
                </>
              }
              isStriked={true}
            />
          );
        })}
      </ModalContent>

      <ModalActions
        items={[
          {
            transKey: "modals.confirmOptionReset.actions.cancel",
            handler: (...props) => {
              dispatch({
                type: "HideModal",
              });
              handleCancel(...props);
            },
          },
          {
            transKey: "modals.confirmOptionReset.actions.confirm",
            type: "primary",
            handler: (...props) => {
              dispatch({
                type: "HideModal",
              });
              handleConfirmReset(...props);
            },
          },
        ]}
      />
    </>
  );
};

Confirm.propTypes = {
  handleConfirmReset: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleEditOption: PropTypes.func,
  options,
};

export default Confirm;
