import React from "react";
import PropTypes from "prop-types";
import { children } from "../../utils/propTypes";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";

import s from "./LoadingScreen.module.css";

const Spinner = () => {
  const { t } = useTranslation();

  return (
    <div className={s.spinner}>
      <CircularProgress />
      <p>{t("utils.loadingScreenText")}</p>
    </div>
  );
};

const LoadingScreen = ({ isLoading, children }) => {
  return (
    <>
      {
        <div className={`${s.container} ${isLoading ? s.show : ""}`}>
          <Spinner />
        </div>
      }

      {children}
    </>
  );
};

LoadingScreen.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children,
};

export default LoadingScreen;
