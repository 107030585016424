export const getWheelHandler = (prevTime, isScrolling, handler) => (e) => {
  // if (isScrolling.current === true) e.preventDefault();
  const curTime = new Date().getTime();
  if (typeof prevTime.current !== "undefined") {
    const timeDiff = curTime - prevTime.current;
    if (timeDiff < 40) {
      if (e.deltaY < 0) {
        if (isScrolling.current === false) {
          isScrolling.current = true;
          handler(false);
        }
      } else if (e.deltaY > 0) {
        if (isScrolling.current === false) {
          isScrolling.current = true;
          handler(true);
        }
      }
    } else {
      isScrolling.current = false;
    }
  }
  prevTime.current = curTime;
};

const getNextEnabled = (refs, prevVal) => {
  let newVal = prevVal;

  for (let i = prevVal + 1; i < refs.length; i++) {
    if (refs[i].isEnabled) {
      newVal = i;
      break;
    }
  }
  return newVal;
};

export const getChangeFocusHandler = (selectDot, currentItem) => (isNext) => {
  if (isNext) {
    selectDot(currentItem + 1);
  } else {
    selectDot(currentItem - 1);
  }
};

export const refPassThrough = (el, wrapperRef, handlers) => {
  handlers.ref(el);
  wrapperRef.current = el;
  return handlers;
};

export const getKeyDownHandler = (handler) => (e) => {
  switch (e.code) {
    case "ArrowRight":
      e.preventDefault();
      return handler(true);
    case "ArrowDown":
      e.preventDefault();
      return handler(true);
    case "ArrowUp":
      e.preventDefault();
      return handler(false);
    case "ArrowLeft":
      e.preventDefault();
      return handler(false);
    default:
      return;
  }
};
