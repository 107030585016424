import React from "react";

const Conditional = ({ when, children }) => {
  return (
    <>
      {when && (children?.[0] ?? children)}
      {!when && children?.[1]}
    </>
  );
};

export default Conditional;
