import React, { useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core";
import { store as modalStore } from "../SharedModal/Store";

const useStyles = makeStyles({
  paper: {
    margin: 16,
    width: "calc(100% - 32px)",
  },
});

const Modal = () => {
  const c = useStyles();

  const {
    state: { showModal, component, disableClose = false },
    dispatch,
  } = useContext(modalStore);

  const closeHandler = () => {
    dispatch({
      type: "HideModal",
    });
  };

  return (
    <Dialog
      classes={c}
      fullWidth
      disableBackdropClick={disableClose}
      disableEscapeKeyDown={disableClose}
      onClose={closeHandler}
      open={showModal}
    >
      {component}
    </Dialog>
  );
};

export default Modal;
