import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";

const a = Analytics({
  app: process.env.REACT_APP_GA_NAME,
  version: 100,
  plugins: [
    googleAnalytics({
      trackingId: process.env.REACT_APP_GA_ID,
      customDimensions: {
        recipeId: "dimension4",
      },
    }),
  ],
});

const track = {
  event: function (action, category = null, label = null, value = null) {
    if (!action) return;
    return a.track(action, {
      category: category,
      label: label,
      value: value,
    });
  },
  page: function (title, path = null) {
    if (!title) return;
    return a.page({
      title: title,
      //url: url,
      path: path,
    });
  },
  user: function (user) {
    const { recipeId } = user;
    if (!recipeId) return;
    return a.identify(recipeId, {
      recipeId: recipeId,
    });
  },
};

export default track;
