import React from "react";
import PropTypes from "prop-types";
import s from "./Design.module.css";
import Heading from "../../atoms/Heading";
import Canvas from "../../molecules/Canvas";
import Footer from "../../molecules/Footer";

import ButtonGroup from "../../molecules/ButtonGroup";

import Tour from "../../tour/tour";

const DesignPage = ({
  isHidden,
  stepsComponent,
  stepsMobileComponent,
  contentProps,
  buttonProps,
}) => {
  return (
    <div data-e2e="designPage" className={s.container} hidden={isHidden}>
      <Heading className={"hide-on-tablet"}>{stepsComponent}</Heading>

      <Tour />
      <Canvas {...contentProps} />

      <Footer>
        <ButtonGroup {...buttonProps} className={"hide-on-tablet"} />

        <div className={"show-on-tablet"} style={{ width: "100%" }}>
          {stepsMobileComponent}
        </div>
      </Footer>
    </div>
  );
};

DesignPage.propTypes = {
  isHidden: PropTypes.bool.isRequired,
  stepsComponent: PropTypes.element.isRequired,
  stepsMobileComponent: PropTypes.element.isRequired,
  contentProps: PropTypes.shape({
    left: PropTypes.element.isRequired,
    right: PropTypes.element.isRequired,
  }).isRequired,
  buttonProps: PropTypes.shape({
    primaryProps: PropTypes.object,
    secondaryProps: PropTypes.object,
  }),
};

export default DesignPage;
