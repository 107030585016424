import React from "react";
import PropTypes from "prop-types";
import s from "./Header.module.css";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import LanguageSelect from "../../molecules/LanguageSelect";
import UnitSelect from "../../molecules/UnitSelect";
import HeaderMenu from "../../molecules/HeaderMenu";
import useHandlers from "./useHandlers";
import IconButton from "@material-ui/core/IconButton";
import { Refresh } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import track from "../../utils/tracking";

const logoUrl = process.env.REACT_APP_LOGO_URL;

const languages = [
  {
    key: "da",
    emoji: "🇩🇰",
  },
  {
    key: "en",
    emoji: "🇬🇧",
  },
];

const units = [
  {
    key: "cm",
    emoji: "cm",
  },
  {
    key: "inches",
    emoji: "in",
  },
];

const menuItems = [
  {
    key: "shareRecipe",
  },
];

const menuItemsMobile = [
  {
    key: "selectUnit",
  },
  {
    key: "shareRecipe",
  },
  {
    key: "resetRecipe",
  },
];

const Header = ({
  handleChangeLanguage,
  handleResetRecipe,
  handleChangeUnit,
  showBackground = false,
  selectedUnit,
  selectedModelType,
}) => {
  const { i18n, t } = useTranslation();

  const {
    selectedLanguage,
    isLanguageSelectOpen,
    isUnitSelectOpen,
    handleLanguageSelectClose,
    handleUnitSelectClose,
    handleLogoClick,
    handleOpenLanguageSelect,
    handleOpenUnitSelect,
    handleSelectMenuItem,
  } = useHandlers({
    i18n,
    handleChangeLanguage,
    handleResetRecipe,
    handleChangeUnit,
  });

  const onResetRecipe = () => {
    handleResetRecipe();
    track.event("ClickResetRecipeFromHeader", "Header");
  };

  return (
    <header className={s.header}>
      <div className={`${showBackground ? s.showBackground : ""} ${s.wrapper}`}>
        <img
          onClick={handleLogoClick}
          className={s.logo}
          src={logoUrl}
          alt={"AlmaKnit Designer"}
        />

        <div className={s.right}>
          {
            <LanguageSelect
              selectedLanguage={selectedLanguage}
              isOpen={isLanguageSelectOpen}
              onClose={handleLanguageSelectClose}
              languages={languages}
            />
          }
          {
            <UnitSelect
              selectedUnit={selectedUnit}
              isOpen={isUnitSelectOpen}
              onClose={handleUnitSelectClose}
              units={units}
            />
          }

          {!isMobile && (
            <>
              <Tooltip title={t("menuItems.resetRecipe")}>
                <IconButton onClick={onResetRecipe}>
                  <Refresh style={{ transform: "rotateY(180deg)" }} />
                </IconButton>
              </Tooltip>
            </>
          )}

          {
            <Tooltip title={t("menuItems.selectLanguage")}>
              <IconButton
                className={s.emojiSelect}
                onClick={handleOpenLanguageSelect}
              >
                <p>
                  {languages.find((el) => el.key === selectedLanguage)?.emoji}
                </p>
              </IconButton>
            </Tooltip>
          }
          {!isMobile && (
            <>
              {
                <Tooltip title={t("menuItems.selectUnit")}>
                  <IconButton
                    className={s.emojiSelect}
                    onClick={handleOpenUnitSelect}
                  >
                    <p>{units.find((el) => el.key === selectedUnit)?.emoji}</p>
                  </IconButton>
                </Tooltip>
              }
            </>
          )}

          <HeaderMenu
            items={isMobile ? menuItemsMobile : menuItems}
            handleSelectItem={handleSelectMenuItem}
          />
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  showBackground: PropTypes.bool,
};

export default React.memo(Header);
