import React from "react";
import PropTypes from "prop-types";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import MUIListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  container: {
    padding: 16,
  },
  nested: {
    paddingLeft: 40,
    fontFamily: "inherit !important",
  },
}));

const ExpandableItem = ({ items, isExpanded, handleClick }) => {
  const c = useStyles();

  return (
    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {items.map((el, i) => {
          return (
            <MUIListItem
              key={`expandableItem-${i}`}
              style={{
                paddingTop: i === 0 ? 24 : 4,
                paddingBottom: i + 1 === items.length ? 24 : 4,
              }}
              className={c.nested}
            >
              <ListItemText
                primaryTypographyProps={{ style: { fontFamily: "inherit" } }}
                secondaryTypographyProps={{ style: { fontFamily: "inherit" } }}
                primary={el.label}
                secondary={el.name}
              />

              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => handleClick(el)}
              >
                <EditIcon fontSize={"small"} />
              </IconButton>
            </MUIListItem>
          );
        })}
      </List>
    </Collapse>
  );
};

ExpandableItem.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      name: PropTypes.string.isRequired,
      stepKey: PropTypes.string.isRequired,
    })
  ),
  isExpanded: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default ExpandableItem;
