import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      light: "#81b9bc",
      main: "#62a8ac",
      dark: "#69ACB0",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: `"Work Sans", "Helvetica", "Arial", sans-serif`,
  },
});

export default theme;
