import React from "react";
import PropTypes from "prop-types";
import s from "./Swatch.module.css";

import SwatchElement from "../../atoms/SwatchElement";
import useSelectItem from "../../hooks/useSelectItem";
import ZoomIn from "../../atoms/ZoomIn";

const Swatch = ({
  items,
  selectedItemKey,
  handleClick,
  noWrap = false,
  ...props
}) => {
  const { selectItem, selected } = useSelectItem({
    selectedItemKey,
    handleClick,
  });

  return (
    <div
      {...props}
      data-e2e="swatchItems"
      className={`${s.items} ${
        items && items.length > 3 && !noWrap ? s.wrap : ""
      }`}
    >
      {items?.map((el, i) => {
        return (
          <ZoomIn delayFactor={i} key={`swatchEl-${i}`}>
            <SwatchElement
              {...{
                ...el,
                size: items.length > 3 ? "small" : "large",
                isSelected: el.key === selected,
                handleClick: () => selectItem(el.key, i),
              }}
            />
          </ZoomIn>
        );
      })}
    </div>
  );
};

Swatch.propTypes = {
  selectedItemKey: PropTypes.string,
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default Swatch;
