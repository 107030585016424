import { useEffect, useMemo, useState, useContext } from "react";
import { store as modalStore } from "../../modals/SharedModal/Store";
import track from "../../utils/tracking";

const useHandlers = ({
  steps,
  options,
  updateOptionsHandler,
  editingOptionFromSummary,
  handleSelectPage,
  handleResetRecipe,
  handleShowEditOptionsModal,
}) => {
  const [selectedStep, setSelectedStep] = useState(0);
  const [
    shouldPreventGoToNextOption,
    setShouldPreventGoToNextOption,
  ] = useState(false);
  const [isFinalNextEnabled, setIsFinalNextEnabled] = useState(true);

  const handleNavButtonClick = (direction) => {
    if (direction === "next") {
      if (selectedStep + 1 < steps.length) {
        setSelectedStep(selectedStep + 1);
        setShouldPreventGoToNextOption(false);
        track.event("GoToNextStepFromBottomButton", "DesignPage");
      } else {
        handleSelectPage(direction);
        track.event("GoToMeasurePageFromBottomButton", "DesignPage");
      }
    } else {
      if (selectedStep > 0) {
        setSelectedStep(selectedStep - 1);
        setShouldPreventGoToNextOption(true);
        track.event("GoToPreviousStepFromBottomButton", "DesignPage");
      } else {
        handleResetRecipe();
        track.event("GoToWelcomePageFromBottomButton", "DesignPage");
      }
    }
  };

  const handleSelectOption = (optionKey, value) => {
    updateOptionsHandler({
      currentOption: {
        optionKey,
        value,
      },
    });
  };

  const optionsPerStep = useMemo(() => {
    return steps.reduce((acc, el) => {
      const enabled = options.filter(
        (o) => o.stepKey === el.key && o.visibilityType === "enabled"
      );
      const completed = enabled.filter(
        (o) =>
          o.value &&
          !(
            (o?.value?.endsWith?.("undefined") ||
              o?.value?.endsWith?.("null")) ??
            false
          )
      );
      return {
        ...acc,
        [el.key]: {
          available: enabled.length,
          completed: completed.length,
          isFulfilled: completed.length === enabled.length,
        },
      };
    }, {});
  }, [options, steps]);

  useEffect(() => {
    const areAllCompleted =
      Object.values(optionsPerStep).filter((el) => !el.isFulfilled).length ===
      0;
    setIsFinalNextEnabled(areAllCompleted);
  }, [optionsPerStep]);

  useEffect(() => {
    if (
      editingOptionFromSummary !== null &&
      editingOptionFromSummary.hasOwnProperty("stepKey")
    ) {
      const stepIndex = steps.findIndex(
        (el) => el.key === editingOptionFromSummary.stepKey
      );
      if (stepIndex > -1) {
        setSelectedStep(stepIndex);
      }
    }
  }, [editingOptionFromSummary]);

  const handleSelectStep = (index) => {
    setSelectedStep(index);
    track.event("SelectStep", "DesignPage", steps?.[index]?.key);
  };

  const handleMissingOptions = () => {
    const allMissing = options.filter(
      (o) => o.visibilityType === "enabled" && !o.value
    );

    track.event(
      "ShowMissingOptionModal",
      "recipe",
      JSON.stringify(allMissing.map((el) => el.key))
    );

    return handleShowEditOptionsModal(true);
  };

  const handleNext = (isDisabled, handleClick) => {
    if (isDisabled) {
      handleMissingOptions();
    } else {
      handleClick();
    }
  };

  const mobileHandleNavButtonClick = (direction, isDisabled, handleClick) => {
    if (direction === "next") {
      handleNext(isDisabled, handleClick);
    } else {
      handleClick(direction);
    }
  };

  return {
    optionsPerStep,
    selectedStep,
    isFinalNextEnabled,
    shouldPreventGoToNextOption,
    setShouldPreventGoToNextOption,
    handleSelectStep,
    handleNavButtonClick,
    handleSelectOption,
    handleNext,
    mobileHandleNavButtonClick,
  };
};

export default useHandlers;
