import Swatch from "../../molecules/Swatch";
import Slider from "../../atoms/Slider";
import TabsSelect from "../../molecules/TabSelect";

export const components = {
  swatchRadio: {
    comp: Swatch,
    props: (el, func) => {
      return {
        items: el.props.items,
        selectedItemKey: el.value,
        handleClick: (itemKey) => func(el.key, itemKey),
      };
    },
  },
  slider: {
    comp: Slider,
    props: (el, func) => {
      const { min, max, step, defaultValue } = el.props;
      return {
        defaultValue: parseInt(defaultValue),
        min: min,
        max: max,
        step: step,
        sliderUnit: el.props.unit,
        elKey: el.key,
        value: el.value,
        handleUpdate: (value) => func(el.key, value),
      };
    },
  },
  tabSelect: {
    comp: TabsSelect,
    props: (el, func) => {
      return {
        items: el.props.items,
        selectedItemKey: el.value,
        handleClick: (itemKey) => func(el.key, itemKey),
      };
    },
  },
};
