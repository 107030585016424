import React, { useContext } from "react";
import { store } from "./Store";
import Alert from "@material-ui/lab/Alert";
import MUISnackbar from "@material-ui/core/Snackbar";

const Snackbar = () => {
  const { state, snackbar } = useContext(store);

  const handleClose = () => {
    snackbar({ isVisible: false });
  };

  return (
    <MUISnackbar
      open={state.isVisible}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        elevation={6}
        variant="filled"
        severity={state.severity}
        style={{ fontFamily: "inherit" }}
      >
        {state.message}
      </Alert>
    </MUISnackbar>
  );
};

export default Snackbar;
