import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { inputs } from "../../utils/propTypes";
import MeasureForm from "../../components/MeasureForm";
import Preview from "../../molecules/Preview";
import { useTranslation } from "react-i18next";
import useHandlers from "./useHandlers";
import usePageview from "../../hooks/usePageview";
import s from "./Measure.module.css";
import Heading from "../../atoms/Heading";
import Canvas from "../../molecules/Canvas";
import Footer from "../../molecules/Footer";
import ButtonGroup from "../../molecules/ButtonGroup";
import Steps from "../../components/Steps";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useStore from "../../store/useStore";
import { useFetch } from "../../hooks/useFetch";
import ApiService from "../../utils/ApiService";

const isLast = (index, arr) => {
  return (arr instanceof Array && index + 1 === arr.length) || false;
};

const MeasurePage = ({
  isHidden,
  handleUpdateValidatedInputs,
  handleInitSummary,
  handleValidField,
  handleBack,
  handleUpdateInputs,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:699px)");

  const { inputs, SetInputs, SetFormStateIsGauge, storedRecipeId } = useStore();

  useFetch(
    () => ApiService.GetInputs(storedRecipeId),
    null,
    true,
    (res) => {
      SetInputs(res.inputs);
    }
  );

  usePageview("MeasurePage");

  const steps = useMemo(() => {
    return inputs.map((el) => {
      const { key, name } = el;
      return { key, name };
    });
  }, [inputs]);

  const optionsPerStep = useMemo(() => {
    return steps.reduce((acc, el) => {
      const enabled =
        inputs
          ?.find((input) => input.key === el.key)
          ?.items?.filter((i) => i.visibilityType === "enabled") ?? [];
      const completed = enabled.filter((o) => o.value);
      return {
        ...acc,
        [el.key]: {
          available: enabled.length,
          completed: completed.length,
          isFulfilled: completed.length === enabled.length,
        },
      };
    }, {});
  }, [inputs, steps]);

  const {
    isLoading,
    isFormValid,
    selectedImageSrc,
    filteredInputs,
    selectedStep,
    handleSelectStep,
    handleVerify,
    handleInvalidateForm,
    handleValidForm,
    handleSelectImage,
  } = useHandlers({
    inputs,
    steps,
    handleInitSummary,
    handleUpdateValidatedInputs,
  });

  const isLastStep = isLast(selectedStep, steps);

  useEffect(() => {
    SetFormStateIsGauge(isLastStep);
  }, [isLastStep]);

  return (
    <div data-e2e="measurePage" className={s.container} hidden={isHidden}>
      <Heading className={"hide-on-tablet"}>
        <Steps
          steps={steps}
          optionsPerStep={optionsPerStep}
          selectedStep={selectedStep}
          handleSelectStep={handleSelectStep}
        />
      </Heading>

      <Canvas
        shouldCenter={false}
        left={!isMobile ? <Preview imageSrc={selectedImageSrc} /> : null}
        right={
          <MeasureForm
            inputs={filteredInputs}
            handleValidField={handleValidField}
            handleValidForm={handleValidForm}
            handleInvalidateForm={handleInvalidateForm}
            handleSelectImage={handleSelectImage}
          />
        }
      />

      <Footer>
        <ButtonGroup
          primaryProps={{
            handleClick: isLastStep
              ? handleVerify
              : () => handleSelectStep(selectedStep + 1),
            isDisabled: !isFormValid,
            label: t(
              isLastStep
                ? isLoading
                  ? "utils.approving"
                  : "utils.approve"
                : "utils.continue"
            ),
            isLoading: isLoading,
            testSelector: "bottomButtonConfirm",
          }}
          secondaryProps={{
            handleClick: isLastStep
              ? () => handleSelectStep(selectedStep - 1)
              : handleBack,
          }}
        />
      </Footer>
    </div>
  );
};

MeasurePage.propTypes = {
  isHidden: PropTypes.bool.isRequired,
  handleUpdateValidatedInputs: PropTypes.func.isRequired,
  handleInitSummary: PropTypes.func.isRequired,
  handleValidField: PropTypes.func.isRequired,
  handleSelectPage: PropTypes.func.isRequired,
  inputs,
};

export default React.memo(MeasurePage);
