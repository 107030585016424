import React, { useEffect, useMemo, useRef, useState } from "react";

import {
  getChangeFocusHandler,
  getWheelHandler,
  refPassThrough,
} from "../helpers";
import { useSwipeable } from "react-swipeable";
import useStore from "../../../store/useStore";

const useCarouselHandlers = ({
  children,
  shouldScrollToTop,
  handleNextPage,
  handlePreviousPage,
  isLastStep,
  shouldSetCurrentItemTo,
  shouldPreventGoToNextOption,
  setShouldPreventGoToNextOption,
}) => {
  const isScrolling = useRef(false);
  const prevTime = useRef(new Date().getTime());
  const wrapperRef = useRef(null);
  const contentRef = useRef(null);
  const timeOut = useRef(null);

  const refs = React.Children.map(children, () => {
    return { ref: React.createRef() };
  });

  //const [currentItem, setCurrentItem] = useState(0);
  const [scrollTo, setScrollTo] = useState(0);
  //const [shouldGoToNextOption, setShouldGoToNextOption] = useState(false);

  const {
    currentItem,
    SetCurrentItem: setCurrentItem,
    SetShouldGoToNextOption: setShouldGoToNextOption,
    carouselRefCount,
    SetCarouselRefsCount,
    SelectCarouselDot,
  } = useStore();

  const selectDot = (index) =>
    SelectCarouselDot(index, handleNextPage, handlePreviousPage);

  const changeFocusHandler = useMemo(() => {
    if (carouselRefCount !== refs.length) {
      SetCarouselRefsCount(refs.length);
    }
    return getChangeFocusHandler(selectDot, currentItem);
  }, [currentItem, refs]);

  const wheelHandler = useMemo(() => {
    return getWheelHandler(prevTime, isScrolling, changeFocusHandler);
  }, [changeFocusHandler]);

  useEffect(() => {
    wrapperRef.current.addEventListener("wheel", wheelHandler);
    return () => wrapperRef.current.removeEventListener("wheel", wheelHandler);
  }, [refs]);

  useEffect(() => {
    if (
      !contentRef.current ||
      !wrapperRef.current ||
      refs.length === 0 ||
      !refs[currentItem]?.ref.current
    )
      return;

    setScrollTo(refs[currentItem].ref.current.offsetTop);
  }, [currentItem]);

  useEffect(() => {
    const p = wrapperRef.current;
    const c = contentRef.current;

    if (!c || !p || refs.length === 0 || !refs[currentItem]?.ref.current)
      return;

    const spaceTop =
      p.clientHeight - refs[currentItem].ref.current.clientHeight;

    const spaceBottom =
      p.clientHeight - refs[refs.length - 1].ref.current.clientHeight;

    c.style.marginTop = spaceTop / 2 + "px";

    if (spaceBottom > 0) {
      c.style.marginBottom = spaceBottom / 2 + "px";
    }
  }, [refs]);

  /* useEffect(() => {
    return;
    const goNext = async (prev) => {
      await new Promise((resolve) => {
        timeOut.current = window.setTimeout(
          resolve,
          parseInt(process.env.REACT_APP_CAROUSEL_TIMEOUT) || 1000
        );
      });

      clearTimeout(timeOut.current);

      if (shouldGoToNextOption && !shouldPreventGoToNextOption) {
        return setCurrentItem(() => {
          if (prev + 1 < refs.length) {
            setShouldGoToNextOption(false);
            return prev + 1;
          } else if (prev + 1 === refs.length) {
            setCurrentItem(0);
            if (!isLastStep) handleNextPage();
            setShouldGoToNextOption(false);
          } else return prev;
        });
      }
    };

    goNext(currentItem);

    return () => clearTimeout(timeOut.current);
  }, [children]);*/

  useEffect(() => {
    if (shouldSetCurrentItemTo !== null) {
      setCurrentItem(shouldSetCurrentItemTo.index);
      shouldSetCurrentItemTo.reset();
    }
  }, [shouldSetCurrentItemTo]);

  useEffect(() => {
    // wrapperRef.current.scrollTo({
    //   top: scrollTo,
    //   behavior: "smooth",
    // });
    wrapperRef.current.scrollTop = scrollTo;
  }, [scrollTo]);

  const swipeHandlers = useSwipeable({
    onSwipedUp: () => changeFocusHandler(true),
    onSwipedDown: () => changeFocusHandler(false),
  });

  const wrapperHandlers = {
    ...swipeHandlers,
    ref: (el) => refPassThrough(el, wrapperRef, swipeHandlers),
  };

  /*const selectDot = (index) => {
    if (index >= refs.length) {
      setCurrentItem(0);
      handleNextPage();
    } else setCurrentItem(index);
    setShouldGoToNextOption(false);
  };*/

  useEffect(() => {
    if (shouldScrollToTop !== currentItem) {
      setCurrentItem(0);
      setShouldGoToNextOption(false);
      clearTimeout(timeOut.current);
    }
  }, [shouldScrollToTop]);

  return {
    wrapperHandlers,
    contentRef,
    refs,
    currentItem,
    selectDot,
    setCurrentItem,
    setShouldGoToNextOption,
  };
};

export default useCarouselHandlers;
