import create from "zustand";
import { Request as R, createYarnUsageObject } from "../utils/helpers";
import ApiService from "../utils/ApiService";
import retry from "retry";
const Request = R.bind({ dispatch: () => console.log("show modal") });

const operation = retry.operation({
  factor: 1.3,
  retries: 5,
  minTimeout: 500,
  maxTimeout: 4000,
});

const WrapRetry = async (func) => {
  return new Promise((resolve, reject) => {
    operation.attempt(() => {
      func()
        .then((res) => {
          if (res instanceof Error) throw res;
          else resolve(res);
        })
        .catch((err) => {
          if (operation.retry(err)) {
            return;
          }
          reject(err ? operation.mainError() : null);
        });
    });
  });
};

const saveInputs = (updated, groupKey, inputs) => {
  const keys = updated.map((el) => el.key);
  return inputs.map((group) => {
    return group.key === groupKey
      ? {
          ...group,
          items: group.items.map((item) => {
            return keys.includes(item.key)
              ? {
                  ...item,
                  value: updated?.find((el) => el.key === item.key)?.value,
                }
              : item;
          }),
        }
      : group;
  });
};

const objToArray = (props = {}) => {
  return Object.entries(props).map(([key, value]) => {
    return { key, value };
  });
};

const useStore = create((set, get) => ({
  isFormValid: false,
  isFormStateGauge: false,
  isLoadingYarnUsage: false,
  SetFormStateIsGauge: (val) => {
    const { RefreshYarnUsageFromRecipe } = get();
    set({ isFormStateGauge: val });
    RefreshYarnUsageFromRecipe();
  },
  SetIsFormValid: (val) => set({ isFormValid: val }),
  storedRecipeId: null,
  SetStoredRecipeId: (id) => set({ storedRecipeId: id }),
  selectedYarn: null,
  inputs: [],
  currentItem: 0,
  shouldGoToNextOption: false,
  carouselRefCount: [],
  SetCurrentItem: (_index) => {
    if (typeof _index === "function") {
      set((state) => ({ currentItem: _index(state.currentItem) }));
    } else {
      set({ currentItem: _index });
    }
  },
  SetCarouselRefsCount: (count) => set({ carouselRefCount: count }),
  SetShouldGoToNextOption: (newVal) => set({ shouldGoToNextOption: newVal }),
  SelectCarouselDot: (index, handleNextPage, handlePreviousPage) => {
    const { carouselRefCount, SetCurrentItem, SetShouldGoToNextOption } = get();
    if (index >= carouselRefCount) {
      SetCurrentItem(0);
      handleNextPage();
    } else if (index >= 0) {
      SetCurrentItem(index);
    } else {
      handlePreviousPage();
    }
    SetShouldGoToNextOption(false);
  },
  yarnUsageSpecs: null,
  RefreshYarnUsageFromRecipe: async (forcedIsValid = false) => {
    await new Promise((res) => setTimeout(res, 200));

    const {
      storedRecipeId,
      isFormValid,
      isFormStateGauge,
      selectedYarn,
    } = get();

    if ((!forcedIsValid && !isFormValid) || !isFormStateGauge) return;

    set({ isLoadingYarnUsage: true });
    //set({ yarnUsageSpecs: [{ name: "Beregner garnforbrug...", value: null }] });

    try {
      const data = await WrapRetry(() =>
        ApiService.GetYarnUsageForRecipe(storedRecipeId, {
          selectedYarnKey: selectedYarn?.key,
        })
      );

      const { yarnMeters, yarns } = data ?? {};

      if (yarnMeters) {
        const _obj = createYarnUsageObject(yarnMeters, yarns ?? []);
        set({ yarnUsageSpecs: [..._obj], isLoadingYarnUsage: false });
      }
    } catch (err) {
      console.log(err);
    }
  },
  SetInputs: (payload, callback) => {
    const { RefreshYarnUsageFromRecipe } = get();
    if (typeof payload === "function") {
      set((state) => ({ inputs: payload(state.inputs) }));
    } else {
      set({ inputs: [...payload] });
    }
    callback?.() || RefreshYarnUsageFromRecipe();
  },
  ApplyPresetOrYarn: async (valuesObj, type = "gauge") => {
    const toBeUpdated = objToArray(valuesObj);

    const { storedRecipeId, SetInputs } = get();

    if (!storedRecipeId) return;

    try {
      await Request(ApiService.UpdateRecipe, storedRecipeId, {
        actionType: "updateInputs",
        value: toBeUpdated,
        allValues: valuesObj,
      });
      const handler = (_inputs) => saveInputs(toBeUpdated, type, _inputs);
      SetInputs(handler);
    } catch (err) {
      console.log(err);
    }
  },
  ResetRecipe: () => {
    set({
      selectedYarn: null,
      yarnUsageSpecs: null,
      isFormValid: false,
    });
  },
  SetSelectedYarn: async (data, callApi = true) => {
    const { key, ...props } = data ?? {};

    const { storedRecipeId } = get();
    try {
      if (callApi) {
        const data = await Request(ApiService.SelectYarn, storedRecipeId, key);
        if (!data) throw "Error";
      }
      set({ selectedYarn: data ? { key, ...props } : null });
    } catch (err) {
      console.log(err);
    }
  },
}));

export default useStore;
