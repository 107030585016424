import React from "react";
import PropTypes from "prop-types";
import { steps, optionsPerStep } from "../../utils/propTypes";
import Component from "./StepsMobile";
import useHandlers from "./useHandlers";

const StepsMobile = ({
  steps,
  optionsPerStep,
  selectedStep,
  isNextEnabled,
  handleNavButtonClickNext,
  handleNavButtonClickPrev,
  handleSelectStep,
}) => {
  const { wrapperRef, refs } = useHandlers({ selectedStep, steps });

  return (
    <Component
      ref={wrapperRef}
      {...{
        refs,
        handleFabNext: () => handleNavButtonClickNext(),
        isNextDisabled: !isNextEnabled,
        handleFabPrevious: () => handleNavButtonClickPrev(),
        steps: steps.map((s, index) => {
          return {
            key: s.key,
            name: `${s.name} (${optionsPerStep[s.key].completed}/${
              optionsPerStep[s.key].available
            })`,
            isSelected: selectedStep === index,
            handleClick: () => handleSelectStep(index),
          };
        }),
      }}
    />
  );
};

StepsMobile.propTypes = {
  selectedStep: PropTypes.number.isRequired,
  handleSelectStep: PropTypes.func.isRequired,
  handleNavButtonClickNext: PropTypes.func.isRequired,
  handleNavButtonClickPrev: PropTypes.func.isRequired,
  isNextEnabled: PropTypes.bool,
  steps,
  optionsPerStep,
};

export default StepsMobile;
