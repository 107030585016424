import React from "react";
import PropTypes from "prop-types";
import s from "./Welcome.module.css";
import { BrowserView } from "react-device-detect";
import Heading from "../../atoms/Heading";
import Canvas from "../../molecules/Canvas";

const WelcomePage = ({
  isHidden,
  title,
  description,
  contentProps,
  buttonProps: { label: buttonLabel, ...buttonRest },
}) => {
  return (
    <div data-e2e="welcomePage" className={s.container} hidden={isHidden}>
      <BrowserView>
        <Heading className={s.heading}>
          <h1>{title}</h1>
          <p dangerouslySetInnerHTML={{ __html: description }} />
        </Heading>
      </BrowserView>

      <Canvas {...contentProps} />
    </div>
  );
};

WelcomePage.propTypes = {
  isHidden: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  description: PropTypes.string.isRequired,
  contentProps: PropTypes.shape({
    left: PropTypes.element.isRequired,
    right: PropTypes.element.isRequired,
  }).isRequired,
  buttonRest: PropTypes.shape({
    handleClick: PropTypes.func.isRequired,
    isNextEnabled: PropTypes.bool.isRequired,
  }),
  buttonLabel: PropTypes.string,
};

export default WelcomePage;
