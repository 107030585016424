import React from "react";
import PropTypes from "prop-types";
import { children } from "../../utils/propTypes";
import s from "./Heading.module.css";

const Heading = ({ className, children }) => {
  return <div className={`${s.container} ${className}`}>{children}</div>;
};

Heading.propTypes = {
  children,
  className: PropTypes.string,
};

export default Heading;
