import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";

const useStyles = makeStyles(() => ({
  root: {
    fontSize: "1.111em",
    padding: "8px 24px",
    fontWeight: 600,
    fontFamily: "inherit",
  },
}));

const Header = ({ title }) => {
  const c = useStyles();

  return (
    <ListSubheader classes={c} component="div">
      {title}
    </ListSubheader>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;
