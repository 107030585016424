import React from "react";
import { actions, children } from "../../../utils/propTypes";
import { useTranslation } from "react-i18next";
import s from "../Modal.module.css";
import Button from "../../../atoms/Button";

const ModalActions = ({ items = [], children }) => {
  const { t } = useTranslation();

  return (
    <div className={s.actions}>
      {items.map((el, i) => {
        return el.href ? (
          <a
            key={`button-${i}`}
            href={el.href}
            target="_blank"
            rel="noreferrer noopener"
          >
            <Button
              color="primary"
              isDisabled={el.isDisabled}
              size={"small"}
              type={el.type}
            >
              {el.transKey ? t(el.transKey) : el.label}
            </Button>
          </a>
        ) : (
          <Button
            color="primary"
            key={`button-${i}`}
            size={"small"}
            type={el.type}
            handleClick={el.handler}
            isDisabled={el.isDisabled}
          >
            {el.transKey ? t(el.transKey) : el.label}
          </Button>
        );
      })}

      {children}
    </div>
  );
};

ModalActions.propTypes = {
  items: actions,
  children,
};

export default ModalActions;
