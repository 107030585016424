import React from "react";
import PropTypes from "prop-types";
import s from "./Canvas.module.css";

const Canvas = ({ left, right, shouldCenter = true }) => {
  return (
    <div className={`${s.container} ${shouldCenter ? s.centerVert : null}`}>
      {left && <div className={s.preview}>{left}</div>}

      {right && <div className={s.settings}>{right}</div>}
    </div>
  );
};

Canvas.propTypes = {
  left: PropTypes.element.isRequired,
  right: PropTypes.element.isRequired,
  shouldCenter: PropTypes.bool,
};

export default Canvas;
