import ModalContent from "../Wrapper/ModelContent";
import ModalHeader from "../Wrapper/ModalHeader";
import ModalActions from "../Wrapper/ModalActions";
import { useTranslation } from "react-i18next";
import s from "./EditOptionsModal.module.css";
import React, { useEffect, useState } from "react";
import Slider from "../../atoms/Slider";
import TabsSelect from "../../molecules/TabSelect";
import Option from "../../molecules/Option";
import Swatch from "../../molecules/Swatch";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  paper: {
    margin: 16,
    width: "calc(100% - 32px)",
  },
});

const EditOptionsModal = ({
  options = [],
  editingOptionKey,
  handleConfirm,
  handler,
  handleClose,
  forceAllowClose = false,
  selectedUnit,
}) => {
  const {
    i18n: { language: currentLanguage },
    t,
  } = useTranslation();

  const filter = (el) =>
    el.visibilityType === "enabled" &&
    ((!el.value && el.value !== 0) ||
      el.value?.includes?.("reset") ||
      (editingOptionKey && el.key === editingOptionKey));

  const [items, setItems] = useState(options.filter(filter));

  const [values, setValues] = useState({});

  const c = useStyles();

  const isValid =
    Object.keys(values).length === items.length ||
    items.every((el) => el.value);

  useEffect(() => {
    setItems((prev) => {
      const filtered = options.filter(filter);
      const merged = [...prev, ...filtered];
      const unique = new Set(merged.map((el) => el.key));
      return Array.from(unique).map((el) => {
        return merged.reverse().find((p) => p.key === el);
      });
    });
  }, [options]);

  const renderItem = (el) => {
    const { min, max, defaultValue, items } = el.props;
    switch (el.type) {
      case "slider":
        return (
          <Slider
            style={{ minWidth: 280 }}
            max={max}
            min={min}
            sliderUnit={el.props.unit}
            selectedUnit={selectedUnit}
            defaultValue={defaultValue}
            value={el.value}
            handleUpdate={(value) => {
              handler(el.key, value);
              setValues((prev) => {
                return { ...prev, [el.key]: 1 };
              });
            }}
          />
        );
      case "swatchRadio":
      case "tabSelect":
        const Component = el.type === "swatchRadio" ? Swatch : TabsSelect;
        return (
          <Component
            items={items}
            selectedItemKey={el.value}
            handleClick={(itemKey) => {
              handler(el.key, itemKey);
              setValues((prev) => {
                return { ...prev, [el.key]: itemKey };
              });
            }}
          />
        );
    }
  };

  return (
    <Dialog
      classes={c}
      disableBackdropClick={!isValid && !forceAllowClose}
      disableEscapeKeyDown={!isValid && !forceAllowClose}
      fullWidth
      open={true}
      onClose={handleClose}
    >
      <ModalHeader
        handleClose={handleClose}
        disableClose={!isValid && !forceAllowClose}
        transKey={"modals.missingOption.title"}
      />

      <ModalContent className={s.content}>
        {items.map((el, i) => {
          const showUnit =
            el.props.unit == "cm" ? t("units." + selectedUnit) : el.props.unit;
          return (
            <Option
              key={el.key}
              className={s.option}
              title={el.props.unit ? `${el.name} (${showUnit})` : el.name}
              optionType={el.type}
              helperLabel={el.helperLabel}
              details={el.details}
              visibilityType={el.visibilityType}
              isFulfilled={Boolean(el.value)}
            >
              {renderItem(el)}
            </Option>
          );
        })}
      </ModalContent>

      <ModalActions
        items={[
          {
            isDisabled: !isValid,
            transKey: t("utils.done"),
            handler: handleConfirm,
          },
        ]}
      />
    </Dialog>
  );
};

export default EditOptionsModal;
