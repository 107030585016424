import { useMemo, useState, useEffect } from "react";

const useHandlers = ({ options, currentStepKey, editingOptionFromSummary }) => {
  const [currentOption, setCurrentOption] = useState(null);

  const filtered = useMemo(
    () =>
      options.filter(
        (el) => el.stepKey === currentStepKey && el.visibilityType !== "hidden"
      ),
    [options, currentStepKey]
  );

  useEffect(() => {
    if (editingOptionFromSummary !== null) {
      const optionIndex = filtered.findIndex(
        (el) => el.key === editingOptionFromSummary.key
      );
      if (optionIndex > -1) {
        setCurrentOption({
          index: optionIndex,
          reset: () => setCurrentOption(null),
        });
      }
    }
  }, [editingOptionFromSummary, filtered]);

  useEffect(() => {
    if (
      editingOptionFromSummary !== null &&
      editingOptionFromSummary.hasOwnProperty("reset")
    ) {
      editingOptionFromSummary.reset();
    }
  }, [currentStepKey]);

  return {
    filtered,
    currentOption,
  };
};

export default useHandlers;
