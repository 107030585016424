import PropTypes from "prop-types";

const visibilityType = PropTypes.oneOf(["enabled", "disabled", "hidden"]);

export const actions = PropTypes.arrayOf(
  PropTypes.shape({
    handler: PropTypes.func,
    type: PropTypes.oneOf(["flat", "secondary", "primary"]),
    transKey: PropTypes.string,
    label: PropTypes.string,
    href: PropTypes.string,
  })
);

export const children = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]);

export const helperLabel = PropTypes.shape({
  type: PropTypes.oneOf(["error", "info"]),
  text: PropTypes.string,
});

export const details = PropTypes.shape({
  title: PropTypes.string.isRequired,
  bodySrc: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    })
  ),
});

export const steps = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    isFulfilled: PropTypes.bool,
  })
);

export const avatarImages = PropTypes.arrayOf(
  PropTypes.shape({
    src: PropTypes.string.isRequired,
    zIndex: PropTypes.number,
  })
);

export const optionsPerStep = PropTypes.objectOf(
  PropTypes.shape({
    available: PropTypes.number.isRequired,
    completed: PropTypes.number.isRequired,
    isFulfilled: PropTypes.bool.isRequired,
  })
);

export const modelTypeObj = PropTypes.shape({
  key: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  shopifyId: PropTypes.number.isRequired,
  modelBaseImageSrc: PropTypes.string.isRequired,
  helperLabel: PropTypes.shape({
    type: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  thumbnailSrc: PropTypes.string,
  coverImageSrc: PropTypes.string,
});

const SwatchRadioProps = PropTypes.shape({
  items: PropTypes.arrayOf(
    PropTypes.shape({
      visibilityType,
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      thumbnailSrc: PropTypes.string,
      helperLabel,
    })
  ),
});

const TabSelectProps = PropTypes.shape({
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
});

const SliderProps = PropTypes.shape({
  defaultValue: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number,
});

export const options = PropTypes.arrayOf(
  PropTypes.shape({
    type: PropTypes.oneOf(["swatchRadio", "tabSelect", "slider"]),
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    stepKey: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    props: PropTypes.oneOfType([SwatchRadioProps, TabSelectProps, SliderProps]),
    visibilityType,
    details,
  })
);

export const inputs = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
        guideImageSrc: PropTypes.string,
        validationType: PropTypes.string,
        validations: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string.isRequired,
            params: PropTypes.array.isRequired,
          })
        ),
        htmlType: PropTypes.oneOf(["text", "number"]),
        helperLabel: PropTypes.string,
        value: PropTypes.oneOfType([
          PropTypes.string.isRequired,
          PropTypes.number.isRequired,
        ]),
      })
    ),
  })
).isRequired;

export const items = PropTypes.arrayOf(
  PropTypes.shape({
    title: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        name: PropTypes.string.isRequired,
        stepKey: PropTypes.string.isRequired,
      })
    ),
  })
);
