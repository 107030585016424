import React from "react";
import PropTypes from "prop-types";
import { avatarImages } from "../../utils/propTypes";
import s from "./Preview.module.css";

const Preview = ({ imageSrc, avatarImages = [], noPadding = false }) => {
  return (
    <div className={`${s.image} ${noPadding ? s.noPadding : ""}`}>
      {avatarImages.map((el, i) => {
        return (
          <img
            key={`model-image-${i}`}
            src={el.src}
            style={{ zIndex: el.zIndex ? el.zIndex : 6 + i }}
          />
        );
      })}

      {imageSrc && <img src={imageSrc} alt="Model" />}
    </div>
  );
};

Preview.propTypes = {
  modelBaseImageSrc: PropTypes.string,
  avatarImages,
  noPadding: PropTypes.bool,
};

export default Preview;
