import React, { useState } from "react";
import PropTypes from "prop-types";
import { items } from "../../../utils/propTypes";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "./ListItem";
import Header from "./Header";
import ExpandableItem from "./ExpandableItem";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 480,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0 0 8px 0 rgba(0,0,0,0.10)",
    overflow: "hidden",
    borderRadius: 8,
  },
}));

const SummaryList = ({ headline, items, handleSelectOption, groupKey }) => {
  const classes = useStyles();

  const [expandedItem, setExpandedItem] = useState(-1);

  const handleClick = (i) => {
    setExpandedItem((prev) => {
      return prev !== i ? i : -1;
    });
  };

  return (
    <List
      component="nav"
      data-e2e={`group-${groupKey}`}
      subheader={<Header title={headline} />}
      className={classes.root}
    >
      {items.map((el, i) => {
        const isExpanded = el.options && i === expandedItem;
        return (
          <ListItem
            data-e2e={"groupItem"}
            key={`listItem-${i}`}
            isExpanded={isExpanded}
            hasDivider={i + 1 < items.length}
            hasButton={Boolean(el.options)}
            title={el.title}
            label={el.label}
            handleClick={() => handleClick(i)}
          >
            {el.options ? (
              <ExpandableItem
                items={el.options}
                isExpanded={isExpanded}
                handleClick={handleSelectOption}
              />
            ) : null}
          </ListItem>
        );
      })}
    </List>
  );
};

SummaryList.propTypes = {
  headline: PropTypes.string.isRequired,
  handleSelectOption: PropTypes.func.isRequired,
  groupKey: PropTypes.string,
  items,
};

export default SummaryList;
