import { useEffect, useState } from "react";

const useSelectItem = ({ selectedItemKey, handleClick }) => {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (selectedItemKey && selectedItemKey.includes("___")) {
      setSelected(selectedItemKey.split("___")[1]);
    } else {
      setSelected(selectedItemKey);
    }
  }, [selectedItemKey]);

  const selectItem = (key, index) => {
    if (key !== selected) {
      setSelected(key);
      handleClick(key, index);
    }
  };

  return {
    selectItem,
    selected,
  };
};

export default useSelectItem;
