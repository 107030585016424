import React, { createContext, useReducer } from "react";
import { reducer } from "./reducer";

const initialState = {
  showModal: false,
  disableClose: false,
  component: <></>,
};

const store = createContext(initialState);

const { Provider } = store;

const ModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, ModalProvider };
