import React from "react";
import Div100vh from "react-div-100vh";
import LoadingScreen from "./molecules/LoadingScreen";
import Header from "./components/Header";
import WelcomePage from "./pages/Welcome";
import DesignPage from "./pages/Design";
import MeasurePage from "./pages/Measure";
import Summary from "./pages/Summary";
import Modal from "./modals/Wrapper";
import useAppHandlers from "./hooks/useAppHandlers";
import useCheckoutHandlers from "./hooks/useCheckoutHandlers";
import styles from "./App.module.css";
import Snackbar from "./components/Snackbar";
import EditOptionsModal from "./modals/EditOptionsModal";
import { useMediaQuery } from "@material-ui/core";
import Conditional from "./atoms/Conditional";
import LoginDialog from "./components/LoginDialog";

const App = ({ shopifyClient }) => {
  const isMobile = useMediaQuery("(max-width: 699px)");

  const {
    isLoading,
    showSummary,
    showEditOptionsModal,
    selectedPage,
    steps,
    avatarImages,
    modelTypes,
    selectedModelType,
    options,
    storedRecipeId,
    editingOptionKey,
    selectedUnit,
    verifyRecipe,
    setShowEditOptionsModal,
    handleResetRecipe,
    handleSelectOption,
    handleChangeLanguage,
    handleChangeUnit,
    handleValidField,
    handleToggleSummary,
    handleSelectPage,
    handleEditOption,
    handleInitiateRecipe,
    handleUpdateOptions,
    handleUpdateInputs,
    handleUpdateValidatedInputs,
    handleSentToCheckout,
  } = useAppHandlers();

  const { checkoutUrl, handleInitSummary } = useCheckoutHandlers({
    shopifyClient,
    selectedModelType,
    storedRecipeId,
    verifyRecipe,
    handleToggleSummary,
  });

  return (
    <LoadingScreen isLoading={isLoading}>
      <Div100vh>
        <div className={styles.main}>
          <Conditional when={!(selectedPage === 3 && isMobile)}>
            <Header
              handleChangeLanguage={handleChangeLanguage}
              handleChangeUnit={handleChangeUnit}
              handleResetRecipe={handleResetRecipe}
              selectedUnit={selectedUnit}
              showBackground={selectedPage === 1}
              selectedModelType={selectedModelType}
            />
          </Conditional>

          <LoginDialog />

          <Snackbar />

          <Modal />

          {showEditOptionsModal && (
            <EditOptionsModal
              options={options}
              editingOptionKey={editingOptionKey}
              selectedUnit={selectedUnit}
              handleConfirm={() => {
                handleSelectPage();
                setShowEditOptionsModal(false);
              }}
              handler={handleSelectOption}
              forceAllowClose={selectedPage === 2}
              handleClose={() => {
                setShowEditOptionsModal(false);
              }}
            />
          )}

          {showSummary && (
            <Summary
              isVisible={showSummary}
              checkoutUrl={checkoutUrl}
              steps={steps}
              options={options}
              avatarImages={avatarImages}
              selectedModelType={selectedModelType}
              handleClose={handleToggleSummary}
              handleEditOption={handleEditOption}
              handleSentToCheckout={handleSentToCheckout}
              selectedUnit={selectedUnit}
            />
          )}

          <div className={styles.content}>
            {selectedPage === 1 && (
              <WelcomePage
                isHidden={false}
                modelTypes={modelTypes}
                selectedModelType={selectedModelType}
                handleInitiateRecipe={handleInitiateRecipe}
              />
            )}

            {selectedPage === 2 && (
              <DesignPage
                steps={steps}
                options={options}
                avatarImages={avatarImages}
                selectedModelType={selectedModelType}
                selectedUnit={selectedUnit}
                handleSelectPage={handleSelectPage}
                editingOptionFromSummary={editingOptionKey}
                handleResetRecipe={handleResetRecipe}
                updateOptionsHandler={handleUpdateOptions}
                handleEditOption={handleEditOption}
                handleShowEditOptionsModal={setShowEditOptionsModal}
              />
            )}

            {selectedPage === 3 && (
              <MeasurePage
                handleUpdateInputs={handleUpdateInputs}
                handleBack={() => handleSelectPage("previous")}
                handleInitSummary={handleInitSummary}
                handleUpdateValidatedInputs={handleUpdateValidatedInputs}
                handleValidField={handleValidField}
              />
            )}
          </div>
        </div>
      </Div100vh>
    </LoadingScreen>
  );
};

export default App;
