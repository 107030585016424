import React from "react";
import PropTypes from "prop-types";
import s from "./Error.module.css";
import ErrorIcon from "@material-ui/icons/Error";
import Button from "../../../atoms/Button";
import ModalContent from "../../Wrapper/ModelContent";
import { useTranslation } from "react-i18next";

const handleReload = () => window.location.replace("/");

const Error = ({ title, msg }) => {
  const { t } = useTranslation();

  return (
    <ModalContent>
      <div className={s.wrapper}>
        <div className={s.icon}>
          <ErrorIcon htmlColor={"var(--color-red)"} fontSize={"inherit"} />
        </div>

        <h2>{title || t("utils.errorTitle")}</h2>

        <p
          dangerouslySetInnerHTML={{ __html: msg || t("utils.errorMessage") }}
        />

        <Button handleClick={handleReload}>{t("utils.tryAgain")}</Button>
      </div>
    </ModalContent>
  );
};

Error.propTypes = {
  title: PropTypes.string,
  msg: PropTypes.string,
};

export default Error;
