import React, { useMemo } from "react";

import s from "./Steps.module.css";
import StepElement from "../../atoms/StepElement";

const renderLabel = (props) => {
  return `${props.completed}/${props.available}`;
};

const determineIsFulfilled = (props) => {
  return props.completed === props.available;
};

const Steps = ({ steps, optionsPerStep, selectedStep, handleSelectStep }) => {
  const rendered = useMemo(() => {
    return steps.map((s, index) => {
      return {
        ...s,
        label: renderLabel(optionsPerStep[s.key]),
        hasNudge:
          selectedStep > index && !determineIsFulfilled(optionsPerStep[s.key]),
        isSelected: selectedStep === index,
        isFulfilled: determineIsFulfilled(optionsPerStep[s.key]),
        handleClick: () => handleSelectStep(index),
      };
    });
  }, [steps, optionsPerStep, selectedStep]);

  return (
    <div data-e2e={"steps"} className={s.container}>
      {rendered.map((el, i) => {
        return <StepElement {...el} index={i} />;
      })}
    </div>
  );
};

export default Steps;
