import React, { useContext } from "react";
import PropTypes from "prop-types";
import s from "../Modal.module.css";
import { useTranslation } from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { store as modalStore } from "../../SharedModal/Store";

const ModalHeader = ({ text, transKey, handleClose, disableClose = false }) => {
  const {
    dispatch,
    state: { lastModal, countLayers },
  } = useContext(modalStore);

  const closeHandler =
    handleClose ??
    (() => {
      if (countLayers === 2) {
        dispatch({ ...lastModal });
      } else
        dispatch({
          type: "HideModal",
        });
    });

  const { t } = useTranslation();

  return (
    <div className={s.header}>
      <h4 className={s.title}>{transKey ? t(transKey) : text}</h4>
      {!disableClose && (
        <IconButton onClick={closeHandler}>
          <CloseIcon />
        </IconButton>
      )}
    </div>
  );
};

ModalHeader.propTypes = {
  text: PropTypes.string,
  transKey: PropTypes.string,
  disableClose: PropTypes.bool,
};

export default ModalHeader;
