import React, { useContext, useEffect, useState } from "react";
import Preview from "../../molecules/Preview";
import { useTranslation } from "react-i18next";
import usePageview from "../../hooks/usePageview";
import s from "./Measure.module.css";
import Heading from "../../atoms/Heading";
import Canvas from "../../molecules/Canvas";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Div100vh from "react-div-100vh";
import styles from "../../App.module.css";
import LoadingScreen from "../../molecules/LoadingScreen";
import { useFetch } from "../../hooks/useFetch";
import RecalibrateForm from "./Form";
import RecalibrateConfig from "./Config";
import Conditional from "../../atoms/Conditional";
import ApiService from "../../utils/ApiService";
import Footer from "../../molecules/Footer";
import ButtonGroup from "../../molecules/ButtonGroup";
import Header from "./Header";
import Snackbar from "../../components/Snackbar";
import { store as snackbarStore } from "../../components/Snackbar/Store";
import Modal from "../../modals/Wrapper";
import useStore from "../../store/useStore";
import fakeAvatarImages from "./fakeAvatarImages";
import { store as modalStore } from "../../modals/SharedModal/Store";

const RecalibratePage = ({ ...props }) => {
  const { i18n, t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:699px)");

  const { snackbar } = useContext(snackbarStore);
  const { dispatch } = useContext(modalStore);

  const { selectedYarn, SetSelectedYarn } = useStore();

  const [committedValues, setCommittedValues] = useState(null);
  const [email, setEmail] = useState(null);
  const [orderNumber, setOrderNumber] = useState(null);
  const [isConfigValid, setIsConfigValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data, error, isLoading, resetHandler, execHandler } = useFetch(
    ApiService.FindRecipe,
    false,
    false
  );

  useEffect(() => {
    if (error) {
      snackbar({
        isVisible: true,
        message: t("recalibrate.error"),
        severity: "error",
      });
    }
  }, [error]);

  const handleReset = () => {
    SetSelectedYarn(null, false);
    resetHandler();
  };

  const submitHandler = async () => {
    try {
      if (!data?.recipeId || !orderNumber || !email) throw "Missing input";
      setIsSubmitting(true);
      const res = await ApiService.SubmitRecalibrateRecipe({
        recipeId: data?.recipeId,
        orderNumber,
        email,
        selectedYarnKey: selectedYarn,
        inputs: { ...committedValues },
      });
      if (res instanceof Error) throw res;
      snackbar({
        isVisible: true,
        message: `${t("recalibrate.success")} ${email}`,
        severity: "success",
      });
      dispatch({
        type: "ShowDetailsModal",
        payload: {
          title: t("recalibrate.successTitle"),
          bodySrc: t("recalibrate.recalibrateSuccessHelperBodySrc"),
        },
      });
      handleReset();
      setCommittedValues(null);
      setEmail(null);
      setIsConfigValid(false);
    } catch (err) {
      snackbar({
        isVisible: true,
        message: t("recalibrate.error"),
        severity: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmitForm = ({ email, orderNumber, ...rest }) => {
    setEmail(email);
    setOrderNumber(orderNumber);
    execHandler({ email, orderNumber, ...rest }, i18n.language);
  };

  usePageview("RecalibratePage");

  return (
    <LoadingScreen isLoading={false}>
      <Div100vh>
        <div className={styles.main}>
          <Conditional when={!Boolean(data)}>
            <Header />
          </Conditional>
          <Snackbar />
          <Modal />
          <div className={styles.content}>
            <div data-e2e="recalibratePage" className={s.container}>
              <Conditional when={!isMobile}>
                <Heading>
                  <h1>{t("recalibrate.title")}</h1>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("recalibrate.description"),
                    }}
                  />
                </Heading>
              </Conditional>
              <Canvas
                shouldCenter={false}
                left={
                  !isMobile && (
                    <Preview
                      avatarImages={data?.avatarImages ?? fakeAvatarImages}
                    />
                  )
                }
                right={
                  <Conditional when={Boolean(data)}>
                    <RecalibrateConfig
                      data={data}
                      handleCommitValues={setCommittedValues}
                      handleIsValid={setIsConfigValid}
                    />
                    <RecalibrateForm
                      isLoading={isLoading}
                      handleSubmit={handleSubmitForm}
                    />
                  </Conditional>
                }
              />

              <Conditional when={Boolean(data)}>
                <Footer>
                  <ButtonGroup
                    secondaryProps={{
                      label: t("buttons.reset"),
                      handleClick: handleReset,
                    }}
                    primaryProps={{
                      label: isSubmitting
                        ? t("recalibrate.buttons.submitting")
                        : t("recalibrate.buttons.submit"),
                      isLoading: isSubmitting,
                      isDisabled:
                        !isConfigValid || !committedValues || isSubmitting,
                      handleClick: submitHandler,
                    }}
                  />
                </Footer>
              </Conditional>
            </div>
          </div>
        </div>
      </Div100vh>
    </LoadingScreen>
  );
};

export default RecalibratePage;
