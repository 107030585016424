import React from "react";
import PropTypes from "prop-types";
import { Box, TextField, makeStyles, InputAdornment } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles({
  root: {
    "& label.Mui-focused": {
      color: "var(--color-main)",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(11px, 15px) scale(1)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.75)",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: 14,
    },
    "&:hover": {
      opacity: 1,
    },
    "& .MuiFormHelperText-root": {
      opacity: 1,
      transition: "opacity 0.1s",
      "&.Mui-focused": {
        opacity: 1,
      },
      "&.Mui-error": {
        opacity: 1,
      },
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "var(--color-main)",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "var(--color-main)",
      },
      "&:hover fieldset": {
        borderColor: "var(--color-main)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--color-main)",
      },
    },
  },
});

const Input = (props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:699px)");
  return props.isSelect ? (
    <Box width={100}>
      <TextField
        {...props}
        select
        classes={classes}
        autoComplete={false}
        variant="standard"
        size="small"
        margin={isMobile ? "dense" : "normal"}
        color={"primary"}
      >
        {props.isSelect.options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
    </Box>
  ) : (
    <TextField
      {...props}
      classes={classes}
      autoComplete={false}
      variant="outlined"
      margin={isMobile ? "dense" : "normal"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{props.unit}</InputAdornment>
        ),
      }}
      color={"primary"}
    />
  );
};
Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["text", "number"]),
  label: PropTypes.string.isRequired,
  color: PropTypes.oneOf(["primary", "secondary"]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  handleBlur: PropTypes.func,
  onFocus: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  helperText: PropTypes.string,
};

export default Input;
