import React from "react";
import PropTypes from "prop-types";
import { children } from "../../../utils/propTypes";
import s from "../Modal.module.css";

const ModalContent = ({ children, className }) => {
  return (
    <div className={`${s.content} ${className ? className : ""}`}>
      {children}
    </div>
  );
};

ModalContent.propTypes = {
  children,
  className: PropTypes.string,
};

export default ModalContent;
