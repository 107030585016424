import React from "react";
import PropTypes from "prop-types";
import { children } from "../../utils/propTypes";
import s from "./Footer.module.css";

const Footer = ({ children, className }) => {
  return (
    <div className={`${className ? className : ""} ${s.container}`}>
      {children}
    </div>
  );
};

Footer.propTypes = {
  children,
  className: PropTypes.string,
};

export default Footer;
