import { useEffect } from "react";
import track from "../../utils/tracking";

const usePageview = (page) => {
  useEffect(() => {
    const url = page
      .split(/(?=[A-Z])/)
      .join("-")
      .toLowerCase();
    track.page(page, `/${url}`);
  }, []);
};

export default usePageview;
