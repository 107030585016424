import deepmerge from "deepmerge";

export const renderNewOptions = (optionsToUpdate, selectedOptions, options) => {
  return updateOptions(optionsToUpdate, options).map((option, i) => {
    return getSelectedKeys(selectedOptions).includes(option.key)
      ? {
          ...option,
          value: getItemKey(option.key, selectedOptions),
        }
      : { ...option, value: null };
  });
};

const updateOptions = (optionsToUpdate, options) => {
  const keys = optionsToUpdate.map((el) => el.key);
  return options.map((item) => {
    return keys.includes(item.key)
      ? mergeUpdates(
          item,
          optionsToUpdate.find((a) => a.key === item.key)
        )
      : item;
  });
};

const mergeUpdates = (item, source) => {
  let merged = { ...item };

  if (source.hasOwnProperty("updates")) {
    merged = deepmerge(item, source.updates);
  }

  if (source.hasOwnProperty("itemsToUpdate")) {
    merged.props.items = updateOptions(source.itemsToUpdate, item.props.items);
  }

  return merged;
};

const getSelectedKeys = (options) =>
  options.map((element) => element.optionKey);

const getItemKey = (optionKey, options) =>
  options.find((item) => item.optionKey === optionKey)?.value;

export const getRandom = () => Math.floor(Math.random() * 10000);

export const getModelType = (key, modelTypes) =>
  modelTypes.items.find((e) => e.key === key);

export const saveInputValue = (payload, inputs) => {
  const elements = Array.isArray(payload) ? [...payload] : [payload];
  const groupKeys = elements.map((el) => el.groupKey);
  return inputs.map((group) => {
    return groupKeys.includes(group.key)
      ? {
          ...group,
          items: group.items.map((item) => {
            const val = elements.find((node) => node.key === item.key)?.value;
            return val ? { ...item, value: val } : item;
          }),
        }
      : group;
  });
};

export const updateInputs = (inputsToUpdate, inputs) => {
  const keys = inputsToUpdate.map((i) => i.key);
  return inputs.reduce((acc, group) => {
    return [
      ...acc,
      {
        ...group,
        items: group.items.map((input) => {
          return keys.includes(input.key)
            ? mergeUpdates(
                input,
                inputsToUpdate.find((el) => el.key === input.key)
              )
            : input;
        }),
      },
    ];
  }, []);
};

export const removeQueryParams = () =>
  window.history.pushState({}, document.title, "/");

export const getQueryParams = () => {
  const str = window.location.search.substring(1);

  if (!str) return {};

  return str.split("&").reduce((acc, item) => {
    const pair = item.split("=");

    return { ...acc, [pair[0]]: pair[1] };
  }, {});
};
