import Lottie from "react-lottie";
import animationData from "./animation.json";

const CheckmarkAnimation = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    speed: 0.5,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return <Lottie options={defaultOptions} height={32} width={32} />;
};

export default CheckmarkAnimation;
