import React, { useState } from "react";
import Select from "react-select";
import Button from "../../atoms/Button";
import { useTranslation } from "react-i18next";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: "var(--color-main)",
  }),
  input: (provided, state) => ({
    ...provided,
    ["& input"]: {
      height: "32px",
    },
  }),
};

const YarnFilters = ({
  filters,
  selectedFilterOptions,
  handleChangeFilter,
  handleClearFilters,
}) => {
  const { t } = useTranslation();

  const mapItems = (items) => {
    var sortedItems = items.sort((a, b) => a.label.localeCompare(b.label)); //sorting the keys before displaying them
    return sortedItems.map(({ key, label }) => {
      return { label, value: key };
    });
  };

  return (
    <>
      <h3>{t("yarnDrawer.filterModal.title")}</h3>
      <label>{t("yarnDrawer.filterModal.description")}</label>

      {filters.map((el) => {
        return (
          <Select
            styles={customStyles}
            key={el.key}
            placeholder={el.name}
            className="basic-single"
            classNamePrefix="select"
            isMulti={true}
            isClearable={true}
            onChange={(items) => handleChangeFilter(items, el.key)}
            name={el.key}
            options={mapItems(el.items)}
            value={selectedFilterOptions?.[el.key] ?? []}
          />
        );
      })}

      <Button
        style={{ color: "light-grey", marginTop: 24 }}
        size={"small"}
        fullwidth
        label={t("buttons.reset")}
        handleClick={handleClearFilters}
      />

      <div style={{ width: "100%", height: 24 }} />
    </>
  );
};

export default YarnFilters;
