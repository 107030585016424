import React, { useReducer, useEffect } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import useLocalStorage from "../hooks/useLocalStorage";
import { useTranslation } from "react-i18next";

// Define our state
const INITIAL_STATE = {
  key: new Date(),
  run: true,
  continuous: true,
  loading: false,
  stepIndex: 0
};


// Set up the reducer function
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "START":
      return { ...state, run: true };
    case "RESET":
      return { ...state, stepIndex: 0 };
    case "STOP":
      return { ...state, run: false };
    case "NEXT_OR_PREV":
      return { ...state, ...action.payload };
    case "RESTART":
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
      };
    default:
      return state;
  }
};

// Define the Tour component
const Tour = ({  }) => {

  const { t, ready: isTranslationReady } = useTranslation();

  const tourSteps = [
    {
      target: ".Options_option__342BY",
      content: t("tour.tourSteps.selectOptions"),
      disableBeacon: true,
    },
    {
      target: ".Dots_dots__3kuYS",
      content: t("tour.tourSteps.dots"),
    },
    {
      target: ".Footer_container__1wfVP",
      content: t("tour.tourSteps.nextStep"),
    }
  ];

  var [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

  tourState.steps = tourSteps;

  const [storedRecipeId, setStoredRecipeId] = useLocalStorage("recipeId", null);

  if (storedRecipeId) {
    tourState.run = false;
  }

  const callback = (data) => {
    const { action, index, type, status } = data;
    if (
      action === ACTIONS.CLOSE ||
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      dispatch({ type: "STOP" });
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
  };

  return (
    <>
      <JoyRide
        {...tourState}
        callback={callback}
        showSkipButton={true}
        styles={{
          tooltipContainer: {
            textAlign: "left",
          },
          options: {
            overlayColor: 'rgba(0, 0, 0, 0)',
            primaryColor: '#62a8ac'
          },
          buttonBack: {
            marginRight: 10,
          },
          buttonNext:{
            borderRadius: 3,
            outline: 'none'
          }
        }}
        locale={{
          last: t("tour.last"),
          next: t("tour.next"),
          back: t("tour.back"),
          skip: t("tour.skip")
        }}
      />
    </>
  );
};
export default Tour;
