import React from "react";
import PropTypes from "prop-types";
import {
  inputs,
  options,
  steps,
  modelTypeObj,
  avatarImages,
} from "../../utils/propTypes";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";

import TransitionWrapper from "./TransitionWrapper";
import Preview from "../../molecules/Preview";
import SummaryContent from "./SummaryContent";
import SummaryAppBar from "./SummaryAppBar";

import s from "./Summary.module.css";

import { isMobile } from "react-device-detect";
import useHandlers from "./useHandlers";
import usePageview from "../../hooks/usePageview";
import YarnSelectedCard from "../../components/YarnSelectedCard";
import useStore from "../../store/useStore";

const useStyles = makeStyles({
  paperFullScreen: {
    backgroundColor: "var(--color-grey-background)",
  },
  paperFullWidth: {
    backgroundColor: "var(--color-grey-background)",
  },
});

const Summary = ({
  isVisible,
  handleClose,
  steps,
  options,
  selectedModelType,
  handleEditOption,
  checkoutUrl,
  handleSentToCheckout,
  avatarImages,
  selectedUnit,
}) => {
  const classes = useStyles();

  const { selectedYarn, inputs } = useStore();

  usePageview("SummaryPage");

  const { open, designGroup, measureGroups } = useHandlers({
    steps,
    inputs,
    options,
    isVisible,
    selectedUnit,
  });

  return (
    <Dialog
      data-e2e={"summaryModal"}
      classes={classes}
      fullScreen={isMobile}
      fullWidth={!isMobile}
      maxWidth={"lg"}
      open={open}
      onClose={handleClose}
      TransitionComponent={TransitionWrapper}
    >
      <SummaryAppBar
        url={checkoutUrl}
        handleClose={handleClose}
        priceLabel={`${selectedModelType.price} DKK`}
        handleSentToCheckout={handleSentToCheckout}
      />

      <div className={s.content}>
        <div className={s.left}>
          <Preview
            noPadding
            imageSrc={selectedModelType?.modelBaseImageSrc ?? ""}
            avatarImages={avatarImages}
          />
        </div>

        <div className={s.right}>
          <SummaryContent
            selectedYarn={selectedYarn}
            title={selectedModelType.name}
            groups={[designGroup, ...measureGroups]}
            handleEditOption={handleEditOption}
          />
        </div>
      </div>
    </Dialog>
  );
};

Summary.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleEditOption: PropTypes.func.isRequired,
  checkoutUrl: PropTypes.string,
  handleSentToCheckout: PropTypes.func.isRequired,
  selectedModelType: modelTypeObj,
  avatarImages,
  steps,
  options,
  inputs,
};

export default Summary;
