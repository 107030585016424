import React from "react";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import ModalHeader from "../../Wrapper/ModalHeader";
import ModalContent from "../../Wrapper/ModelContent";
import ModalActions from "../../Wrapper/ModalActions";
import Button from "../../../atoms/Button";
import useHandlers from "./useHandlers";
import { makeStyles } from "@material-ui/core/styles";
import CopyLink from "./CopyLink";

const useStyles = makeStyles({
  root: {
    "&:hover .MuiInput-underline:before": {
      borderBottomColor: "rgba(0,0,0,0.5)",
    },
  },
});

const Share = () => {
  const { t } = useTranslation();

  const c = useStyles();

  const {
    isEmailValid,
    url,
    error,
    handleCopyLink,
    handleChange,
    handleSubmit,
  } = useHandlers();

  return (
    <>
      <ModalHeader transKey={"modals.share.title"} />

      <ModalContent>
        <p>{t("modals.share.description")}</p>

        {url && (
          <CopyLink
            url={url}
            buttonLabel={t("modals.share.actions.copy")}
            style={{ marginTop: 24 }}
            handleClick={handleCopyLink}
          />
        )}

        <TextField
          classes={c}
          onChange={handleChange}
          autoFocus
          margin="normal"
          id="message"
          label={t("modals.share.messageInputLabel")}
          type="text"
          multiline={true}
          fullWidth
          helperText={error}
        />

        <TextField
          classes={c}
          onChange={handleChange}
          error={Boolean(error)}
          margin="normal"
          id="email"
          label={t("modals.share.emailInputLabel")}
          type="email"
          fullWidth
          helperText={error}
        />
      </ModalContent>

      <ModalActions>
        <Button
          handleClick={handleSubmit}
          size={"small"}
          type={"primary"}
          style={{ marginLeft: 8 }}
          isDisabled={!isEmailValid}
        >
          {t("modals.share.actions.sendEmail")}
        </Button>
      </ModalActions>
    </>
  );
};

export default Share;
