import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

import s from "./Heading.module.css";

const HeadingSkeleton = () => {
  return (
    <div className={s.container}>
      <Skeleton height={40} animation="wave" width={"70%"} />

      <Skeleton height={24} animation="wave" width={"100%"} />
    </div>
  );
};

export default HeadingSkeleton;
