import React from "react";
import ShareModal from "./Share";
import DetailsModal from "./Details";
import ErrorModal from "./Error";
import CloneModal from "./Clone";
import ConfirmModal from "./Confirm";
import YarnUsageModal from "./YarnUsage";

export const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_PROPS": {
      return {
        ...state,
        ...action.payload,
      };
    }

    case "HideModal": {
      return {
        ...state,
        showModal: false,
      };
    }
    case "ShowDetailsModal": {
      const { title, actions, bodySrc } = action.payload;

      return {
        showModal: true,
        component: (
          <DetailsModal
            bodySrc={bodySrc}
            actions={actions}
            title={{ text: title }}
          />
        ),
      };
    }
    case "ShowShareModal":
      return {
        showModal: true,
        component: <ShareModal />,
      };

    case "ShowErrorModal": {
      const { title, msg } = action.payload;

      return {
        showModal: true,
        disableClose: true,
        component: <ErrorModal title={title} msg={msg} />,
      };
    }
    case "ShowCloneModal": {
      const { handleCustomize, handleStartFromScratch } = action.payload;

      return {
        showModal: true,
        disableClose: true,
        component: (
          <CloneModal
            handleCustomize={handleCustomize}
            handleStartFromScratch={handleStartFromScratch}
          />
        ),
      };
    }
    case "ShowConfirmModal":
      const {
        options,
        handleEditOption,
        handleConfirmReset,
        handleCancel,
      } = action.payload;

      return {
        showModal: true,
        disableClose: true,
        component: (
          <ConfirmModal
            options={options}
            handleEditOption={handleEditOption}
            handleConfirmReset={handleConfirmReset}
            handleCancel={handleCancel}
          />
        ),
      };

    case "ShowYarnUsageModal":
      return {
        type: "yarnUsage",
        showModal: true,
        component: <YarnUsageModal />,
      };

    default:
      return state;
  }
};
