import { useEffect, useState } from "react";
import { composeGid } from "@shopify/admin-graphql-api-utilities";
import Client from "shopify-buy";
import { useTranslation } from "react-i18next";

const useCheckoutHandlers = ({
  shopifyClient,
  selectedModelType,
  storedRecipeId,
  handleToggleSummary,
  verifyRecipe,
}) => {
  const [checkout, setCheckout] = useState(null);
  const [checkoutUrl, setCheckoutUrl] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    var currentCheckoutLan = "da-DA"; //set default check-out language

    if (i18n.language) {
      //when a language is providede, check-out language is update
      currentCheckoutLan = i18n.language + "-" + i18n.language.toUpperCase();
    }

    //create a new client with the correct language
    const shopifyClientNEW = Client.buildClient({
      // apiVersion: "2023-07",
      domain: process.env.REACT_APP_SHOPIFY_DOMAIN,
      storefrontAccessToken: process.env.REACT_APP_SHOPIFY_ACCESS_TOKEN,
      language: currentCheckoutLan,
    });

    shopifyClient = shopifyClientNEW;

    shopifyClient.checkout.create().then((res) => {
      setCheckout(res);
    });
  }, [shopifyClient, i18n.language]);

  const initCheckout = () => {
    const selectedModelTypeId = selectedModelType?.shopifyId;

    if (!selectedModelTypeId) return;

    const lineItemsToAdd = [
      {
        variantId: btoa(composeGid("ProductVariant", selectedModelTypeId)),
        quantity: 1,
        customAttributes: [{ key: "recipeId", value: storedRecipeId }],
      },
    ];

    const checkoutId = checkout.id;

    return shopifyClient.checkout
      .replaceLineItems(checkoutId, lineItemsToAdd)
      .then((res) => {
        setCheckout(res);
        return res.webUrl;
      });
  };

  const handleInitSummary = async (setLoading) => {
    const isVerified = await verifyRecipe(setLoading);

    if (isVerified) {
      handleToggleSummary();
      const url = await initCheckout();
      setCheckoutUrl(url);
    }
  };

  return {
    handleInitSummary,
    checkoutUrl,
  };
};

export default useCheckoutHandlers;
