import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Option from "../../../molecules/Option";
import YarnDrawer from "../../../components/YarnDrawer";
import Input from "../../../atoms/Input";
import YarnSpecs from "../../../components/YarnSpecs";
import { isEmpty } from "../../../components/MeasureForm/helpers";
import useHandlers from "../../../components/MeasureForm/useHandlers";
import ApiService from "../../../utils/ApiService";
import { createYarnUsageObject } from "../../../utils/helpers";
import { useFetch } from "../../../hooks/useFetch";
import useStore from "../../../store/useStore";
import { useTranslation } from "react-i18next";

const Styled = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 440px;
  padding: 24px;
  position: absolute;
  min-height: 100%;

  & > * {
    width: 100%;
  }

  & .items {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
  }
`;

const RecalibrateConfig = ({ data, handleIsValid, handleCommitValues }) => {
  const [yarnUsage, setYarnUsage] = useState(null);
  const { selectedYarn, SetSelectedYarn, RefreshYarnUsageFromRecipe } =
    useStore(); //6 fec 2024 - also get RefreshYarnUsageFromRecipe from store

  const { t } = useTranslation();

  const handleValidField = console.log;
  const handleValidForm = () => {
    handleCommitValues(values);
    handleIsValid(true);
  };
  const handleInvalidateForm = () => {
    handleIsValid(false);
  };

  const { data: yarnData, execHandler } = useFetch((payload) =>
    ApiService.GetYarns(data.recipeId, { ...payload, includeFilters: true })
  );

  const {
    inputGroups,
    values,
    touched,
    errors,
    isValid,
    isValidating,
    handleChange,
    setValues,
  } = useHandlers({
    inputs: data?.inputs ?? [],
    handleValidForm,
    handleInvalidateForm,
    handleValidField,
    RefreshYarnUsageFromRecipe, //6 fec 2024 - also give RefreshYarnUsageFromRecipe which was added as an input in october
  });

  const handleRefreshYarnUsage = useCallback(async () => {
    setYarnUsage([{ name: t("yarnUsage.loading"), value: null }]);

    await new Promise((resolve) => setTimeout(resolve, 500));

    try {
      const _data = await ApiService.GetYarnUsageForRecalibrate({
        storedRecipeId: data.recipeId,
        inputs: values,
      });

      const { yarnMeters, yarns } = _data ?? {};

      if (yarnMeters) {
        const _obj = createYarnUsageObject(yarnMeters, yarns ?? []);
        setYarnUsage([..._obj]);
      }
    } catch (err) {
      console.log(err);
    }
  }, [values]);

  useEffect(() => {
    if (isValid && isEmpty(errors) && !isValidating) {
      handleRefreshYarnUsage();
    }
  }, [values, errors, isValidating]);

  const handleSelectYarn = (props) => {
    setValues(props);
  };

  const _SetSelectedYarn = (data) => SetSelectedYarn(data, false);

  return (
    <Styled>
      <Option
        title={t("recalibrate.gauge.title")}
        helperLabel={{
          type: "info",
          text: t("recalibrate.gauge.helperLabel"),
        }}
        details={{
          title: t("recalibrate.gauge.tooltipTitle"),
          bodySrc: t("yarnUsage.yarnUsageHelperBodySrc"),
        }}
      >
        <YarnDrawer
          preSelectedYarn={data?.selectedYarnKey}
          handleSelectItem={handleSelectYarn}
          data={yarnData}
          selectedYarn={selectedYarn ?? data?.selectedYarnKey}
          GetYarns={execHandler}
          SelectYarn={_SetSelectedYarn}
        />
      </Option>

      <div className="items">
        {inputGroups.map((i, index) => {
          return i.items.map((el, pos) => {
            return (
              <Input
                key={`input-${el.key}`}
                name={el.key}
                type={"text"}
                label={el.label}
                color={"primary"}
                value={values[el.key] || ""}
                onChange={handleChange}
                error={touched[el.key] && Boolean(errors[el.key])}
                helperText={
                  (touched[el.key] && errors[el.key]) || el.helperLabel
                }
              />
            );
          });
        })}
      </div>

      <YarnSpecs style={{ width: "100%", marginTop: 24 }} items={yarnUsage} />
    </Styled>
  );
};

export default RecalibrateConfig;
