import React, { useState } from "react";
import PropTypes from "prop-types";
import { default as MUISlider } from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";
import { cm2SelectedUnit } from "../../utils/helpers";

const StyledSlider = withStyles({
  root: {
    color: "var(--color-main)",
    height: 8,
    maxWidth: 280,
    marginTop: 8,
  },
  thumb: {
    height: 30,
    width: 30,
    backgroundColor: "#fff",
    border: "1px solid var(--color-grey-light)",
    boxShadow: "0 0 4px 0 rgba(0,0,0,0.1)",
    marginTop: -12,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 12px)",
  },
  mark: {
    height: 8,
    width: 1,
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
    backgroundColor: "#EBEBEB",
    opacity: 1,
  },
})(MUISlider);

function valueLabelFormat(value, sliderUnit, selectedUnit) {
  return sliderUnit === "cm" ? cm2SelectedUnit(selectedUnit, value) : value;
}

const Slider = ({
  defaultValue,
  min,
  max,
  step = 1,
  sliderUnit = "",
  handleUpdate,
  value,
  selectedUnit,
  ...props
}) => {
  const handleChange = (event, newValue) => setVal(newValue);

  const commitChange = (event, newValue) => handleUpdate(newValue);

  const [val, setVal] = useState(value || min); // || defaultValue);

  var marks = [];
  for (let i = min; i < max; i += step) {
    marks.push({ value: i });
  }

  return (
    <>
      <StyledSlider
        min={min}
        max={max}
        step={step}
        marks={marks}
        valueLabelDisplay="on"
        value={val}
        valueLabelFormat={(x) => valueLabelFormat(x, sliderUnit, selectedUnit)}
        onChange={handleChange}
        onChangeCommitted={commitChange}
        data-e2e={"sliderElement"}
        {...props}
      />
    </>
  );
};

Slider.propTypes = {
  defaultValue: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  step: PropTypes.number,
  value: PropTypes.number,
};

export default Slider;
