import { useEffect, useState } from "react";

export const useFetch = (
  api,
  defaultPayload = {},
  fetchOnMount = true,
  setter = null,
  valueToWatch = null
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (valueToWatch) {
      getItems();
    }
  }, [valueToWatch]);

  const execHandler = (...params) => {
    getItems(...params);
  };

  const getItems = async (payload, ...params) => {
    try {
      setIsLoading(true);
      const res = await api({ ...payload, ...defaultPayload }, ...params);
      if (res instanceof Error) throw res;
      setData(res);
      if (setter) setter(res);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const resetHandler = () => {
    setIsLoading(false);
    setError(null);
    setData(null);
  };

  useEffect(() => {
    if (fetchOnMount) getItems(defaultPayload);
  }, []);

  return {
    isLoading,
    data,
    error,
    resetHandler,
    execHandler,
  };
};
