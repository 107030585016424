import React, { createContext, useState } from "react";

const initialState = {
  isVisible: false,
  message: "This is a test",
  severity: "success",
};

const store = createContext(initialState);

const { Provider } = store;

const SnackbarProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  const snackbar = (props) => {
    setState((prev) => {
      return {
        ...prev,
        ...props,
      };
    });
  };

  return <Provider value={{ state, snackbar }}>{children}</Provider>;
};

export { store, SnackbarProvider };
