import React, { useEffect, useMemo, useRef, useState } from "react";
import OptionTitle from "../../../atoms/OptionTitle";
import Input from "../../../atoms/Input";
import Button from "../../../atoms/Button";
import Flex from "../../../atoms/Flex";
import * as yup from "yup";
import { validate } from "../../../utils/helpers";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Styled = styled.form`
  width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const RecalibrateForm = ({ isLoading, handleSubmit }) => {
  const timerRef = useRef();

  const { t } = useTranslation();

  const [formState, setFormState] = useState({});
  const [formError, setFormError] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  const formSchema = useMemo(
    () =>
      yup.object().shape({
        orderNumber: yup.string().required(t("validation.required")),
        email: yup
          .string()
          .email(t("validation.email"))
          .required(t("validation.required")),
      }),
    [t]
  );

  const handleUpdateForm = (key) => (e) => {
    window.clearTimeout(timerRef.current);
    const { value } = e.target;
    timerRef.current = window.setTimeout(() => {
      setFormState(async (prev) => {
        const newValue = { ...(await prev), [key]: value };
        try {
          await validate(newValue, formSchema);
          setFormError({});
          setIsFormValid(true);
          return newValue;
        } catch ({ path, message, ...rest }) {
          setFormError({ [path]: message });
          return newValue;
        }
      });
    }, 500);
  };

  const onSubmit = async () => {
    handleSubmit({ ...(await formState) });
  };

  useEffect(() => {
    if (Boolean(formState["email"]) && Boolean(formState["orderNumber"])) {
      setIsFormValid(true);
    }
  }, [formState]);

  return (
    <Styled>
      <OptionTitle title={t("recalibrate.form.title")} />

      <Input
        fullWidth
        type={"email"}
        autoComplete
        name={"email"}
        spellCheck={false}
        label={t("recalibrate.form.inputs.emailPlaceholder")}
        onChange={handleUpdateForm("email")}
        error={formError["email"]}
        helperText={
          formError["email"] || t("recalibrate.form.inputs.emailLabel")
        }
      />
      <Input
        fullWidth
        type={"string"}
        spellCheck={false}
        name={"orderNumber"}
        label={t("recalibrate.form.inputs.orderNumberPlaceholder")}
        onChange={handleUpdateForm("orderNumber")}
        error={formError["orderNumber"]}
        helperText={
          formError["orderNumber"] ||
          t("recalibrate.form.inputs.orderNumberLabel")
        }
      />

      <Button
        style={{ marginTop: 16 }}
        label={t("recalibrate.form.buttons.findRecipe")}
        fullWidth
        type={"primary"}
        isDisabled={isLoading || !isFormValid}
        isLoading={isLoading}
        handleClick={onSubmit}
      />
    </Styled>
  );
};

export default RecalibrateForm;
