import React from "react";
import PropTypes from "prop-types";
import s from "./Dots.module.css";
import { ArrowUpward, ArrowDownward } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import track from "../../utils/tracking";

const Dots = ({
  items,
  selectedIndex,
  className,
  handleClick,
  hideNextArrow = false,
  showButtons = true,
  setShouldPreventGoToNextOption,
}) => {
  const handleButtonClick = (direction) => {
    if (direction === "next") {
      handleClick(selectedIndex + 1);
      setShouldPreventGoToNextOption(false);
      track.event("GoToNextOptionsByArrowDown", "DesignPage");
    } else {
      handleClick(selectedIndex - 1);
      setShouldPreventGoToNextOption(true);
      track.event("GoToPreviousOptionsByArrowUp", "DesignPage");
    }
  };

  const onClick = (index) => {
    handleClick(index);
    track.event("GoToOptionsByClickingDot", "DesignPage", index);
  };

  return (
    <div className={`${s.container} ${className}`}>
      {showButtons && (
        <IconButton
          className={s.button}
          onClick={() => handleButtonClick("previous")}
        >
          <ArrowUpward htmlColor={"var(--color-main)"} />
        </IconButton>
      )}

      <div data-e2e={"dots"} className={s.dots}>
        {items.map((el, i) => {
          return (
            <div
              key={`dot-${i}`}
              className={`${s.dot} ${i === selectedIndex ? s.selected : ""} ${
                !el.isFulfilled
                  ? el.visibilityType === "enabled"
                    ? s.nudge
                    : s.disabled
                  : ""
              }`}
              onClick={() => onClick(i)}
            />
          );
        })}
      </div>

      {!hideNextArrow && showButtons && (
        <IconButton
          className={s.button}
          onClick={() => handleButtonClick("next")}
        >
          <ArrowDownward htmlColor="var(--color-main)" />
        </IconButton>
      )}
    </div>
  );
};

Dots.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      ref: PropTypes.object,
    })
  ).isRequired,
  selectedIndex: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default Dots;
