import React from "react";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useTranslation } from "react-i18next";
import ModalHeader from "../../Wrapper/ModalHeader";
import ModalContent from "../../Wrapper/ModelContent";
import ModalActions from "../../Wrapper/ModalActions";
import Button from "../../../atoms/Button";
import useHandlers from "./useHandlers";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    "&:hover .MuiInput-underline:before": {
      borderBottomColor: "rgba(0,0,0,0.5)",
    },
  },
});

const YarnUsage = () => {
  const { t } = useTranslation();

  const c = useStyles();

  const {
    formState,
    isFormValid,
    error,
    yarnUsageResult,
    handleChange,
    handleSubmit,
  } = useHandlers();

  return (
    <>
      <ModalHeader transKey={"modals.yarnUsage.title"} />

      <ModalContent>
        <p>{t("modals.yarnUsage.description")}</p>

        <TextField
          classes={c}
          onChange={handleChange}
          autoFocus
          margin="normal"
          error={Boolean(error.gauge)}
          id="gauge"
          label={t("modals.yarnUsage.gaugeLabel")}
          type="number"
          multiline={false}
          fullWidth
          helperText={error.gauge}
        />

        <TextField
          classes={c}
          onChange={handleChange}
          error={Boolean(error.chest)}
          margin="normal"
          id="chest"
          label={t("modals.yarnUsage.chestLabel")}
          type="number"
          fullWidth
          helperText={error.chest}
        />
        <TextField
          classes={c}
          onChange={handleChange}
          error={Boolean(error.yarnMeters)}
          margin="normal"
          id="yarnMeters"
          label={t("modals.yarnUsage.yarnMetersLabel")}
          type="number"
          fullWidth
          helperText={error.yarnMeters}
        />

        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={formState.balloonSleeves}
                onChange={handleChange}
                id="balloonSleeves"
              />
            }
            label={t("modals.yarnUsage.balloonSleevesLabel")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formState.rollNeck}
                onChange={handleChange}
                id="rollNeck"
              />
            }
            label={t("modals.yarnUsage.rollNeckLabel")}
          />
        </FormGroup>

        <div>
          {" "}
          {yarnUsageResult.yarnUsage != null
            ? t("modals.yarnUsage.yarnUsageResult") + yarnUsageResult.yarnUsage
            : ""}
        </div>
        <div>
          {" "}
          {yarnUsageResult.ballsOfYarn != null
            ? t("modals.yarnUsage.ballsOfYarnResult") +
              yarnUsageResult.ballsOfYarn
            : ""}
        </div>
      </ModalContent>

      <ModalActions>
        <Button
          handleClick={handleSubmit}
          size={"small"}
          type={"primary"}
          isDisabled={!isFormValid}
        >
          {t("modals.yarnUsage.actions.calculate")}
        </Button>
      </ModalActions>
    </>
  );
};

export default YarnUsage;
