import { useEffect, useMemo, useRef, useState } from "react";
import {
  createYupSchema,
  flattenSchemas,
  isEmpty,
  extractInitials,
} from "./helpers";
import * as Yup from "yup";
import { useFormik } from "formik";

const useHandlers = ({
  inputs,
  handleValidForm,
  handleInvalidateForm,
  handleValidField,
  RefreshYarnUsageFromRecipe,
}) => {
  const yupSchemaInit = flattenSchemas(inputs).reduce(createYupSchema, {});
  const validateSchemaInit = Yup.object().shape(yupSchemaInit);

  const [validateSchema, setvalidateSchema] = useState(validateSchemaInit);

  const validationTimeout = useRef(null);

  const extractedInitials = useMemo(() => {
    return extractInitials(inputs);
  }, [inputs]);

  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    isValid,
    isValidating,
    setFieldValue,
    setValues,
    validateForm,
  } = useFormik({
    initialValues: extractedInitials,
    initialTouched: extractedInitials,
    validationSchema: validateSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log("submitting");
    },
  });

  const handleCustomBlur = (e, el) => {
    handleBlur(e);
    if (!errors[e.target.name]) {
      handleValidField(el, values);
      const yupSchemaInitBlur = flattenSchemas(inputs).reduce(
        createYupSchema,
        {}
      );
      const validateSchemaInitBlur = Yup.object().shape(yupSchemaInitBlur);
      setvalidateSchema(validateSchemaInitBlur);
    }
  };

  useEffect(() => {
    clearTimeout(validationTimeout.current);

    //14.10.23: bug fix. Adding to update validation schema when changing pages.
    validateForm().then((x) => console.log(x));
    const yupSchemaInitUseEffect = flattenSchemas(inputs).reduce(
      createYupSchema,
      {}
    );
    const validateSchemaInitUseEffect = Yup.object().shape(
      yupSchemaInitUseEffect
    );
    setvalidateSchema(validateSchemaInitUseEffect);

    //14.10.23: call to get yarnusaage when loading pattern
    RefreshYarnUsageFromRecipe();

    if (!isEmpty(touched) && isValid) {
      const validated = inputs.reduce((acc, g) => {
        return [
          ...acc,
          ...g.items.map((i) => {
            return { ...i, groupKey: g.key, value: values[i.key] };
          }),
        ];
      }, []);
      validationTimeout.current = setTimeout(() => {
        handleValidForm(validated);
      }, 100);
    } else {
      handleInvalidateForm();
    }
  }, [isValid, touched]);

  return {
    inputGroups: inputs,
    values,
    touched,
    errors,
    isValid,
    isValidating,
    handleChange,
    handleCustomBlur,
    setFieldValue,
    setValues,
    handleSubmit,
  };
};

export default useHandlers;
