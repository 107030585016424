import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const HeaderMenu = ({ items, handleSelectItem }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickItem = (key) => {
    handleSelectItem(key);
    setAnchorEl(null);
  };

  const handleClose = () => setAnchorEl(null);

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map((el, i) => {
          return (
            <MenuItem
              key={`headerItem-${i}`}
              style={{ fontFamily: "inherit" }}
              onClick={() => handleClickItem(el.key)}
            >
              {t(`menuItems.${el.key}`)}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

HeaderMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
    })
  ),
  handleSelectItem: PropTypes.func,
};

export default HeaderMenu;
