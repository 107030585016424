import React from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Dialog from "@material-ui/core/Dialog";

import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: "rgba(200,200,200,1)",
  },
  selected: {
    border: "2px solid var(--color-main)",
    backgroundColor: "rgba(200,200,200,1)",
  },
  item: {
    opacity: 0.7,
    ["&:hover"]: {
      opacity: 1,
    },
  },
  selectedItem: {
    opacity: 1,
  },
});

const useDialogClasses = makeStyles({
  paper: {
    minWidth: 240,
    ["& p"]: {
      fontSize: "0.9em",
    },
  },
});

const UnitSelect = ({ onClose, selectedUnit, isOpen, units }) => {
  const classes = useStyles();
  const c = useDialogClasses();
  const { t } = useTranslation();

  const handleClose = () => {
    onClose(selectedUnit);
  };

  const handleListItemClick = (key) => {
    onClose(key);
  };

  return (
    <Dialog onClose={handleClose} open={isOpen} classes={c}>
      <List>
        {units.map((unit) => (
          <ListItem
            button
            className={
              selectedUnit === unit.key ? classes.selectedItem : classes.item
            }
            onClick={() => handleListItemClick(unit.key)}
            key={unit.key}
          >
            <ListItemAvatar>
              <Avatar
                className={`${
                  selectedUnit === unit.key ? classes.selected : classes.avatar
                }`}
              >
                {unit.emoji}
              </Avatar>
            </ListItemAvatar>
            <p>{t(`units.${unit.key}`)}</p>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

UnitSelect.propTypes = {
  onClose: PropTypes.func.isRequired,
  selectedUnit: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      emoji: PropTypes.string.isRequired,
    })
  ),
};

export default UnitSelect;
