import React from "react";
import styled from "styled-components";

const Styled = styled.div`
  display: flex;
  flex-direction: ${(p) => p.direction};
  justify-content: ${(p) => p.alignX};
  align-items: ${(p) => p.alignY};
  width: ${(p) => p.width};
`;

const Flex = ({
  direction = "row",
  alignX = "center",
  alignY = "center",
  width = "auto",
  children,
  ...props
}) => {
  return (
    <Styled {...props} {...{ direction, alignX, alignY, width }}>
      {children}
    </Styled>
  );
};

export default Flex;
