import { useState } from "react";

const defaultMaxAgeMins =
  60 * 24 * (process.env.REACT_APP_FIDDLERY_COOKIE_EXPIRY_DAYS || 7); // 7 days

const setCookie = (cname, cvalue, maxAgeMins = defaultMaxAgeMins) => {
  const d = new Date();
  d.setTime(d.getTime() + maxAgeMins * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  window.document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

const readCookie = (name) => {
  if (!name) return;
  const cookieObj = window.document.cookie.split(";").reduce((acc, pair) => {
    const [key, value] = pair.trim().split("=");
    return { ...acc, [key]: value };
  }, {});
  return cookieObj[name];
};

const useCookie = (name) => {
  const [storedValue, setStoredValue] = useState(readCookie(name) ?? false);

  const setValue = (value) => {
    const valueToStore = value instanceof Function ? value() : value;
    setStoredValue(valueToStore);
    setCookie(name, valueToStore);
  };

  return [storedValue, setValue];
};

export default useCookie;
