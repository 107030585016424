import React from "react";
import PropTypes from "prop-types";
import {
  steps,
  options,
  avatarImages,
  modelTypeObj,
} from "../../utils/propTypes";
import Component from "./DesignPage";
import Preview from "../../molecules/Preview";
import Options from "../../components/Options";
import Steps from "../../components/Steps";
import StepsMobile from "../../components/StepsMobile";
import useHandlers from "./useHandlers";
import usePageview from "../../hooks/usePageview";
import useStore from "../../store/useStore";

const DesignPage = ({
  isHidden,
  steps,
  avatarImages,
  options,
  selectedModelType,
  selectedUnit,
  editingOptionFromSummary,
  updateOptionsHandler,
  handleSelectPage,
  handleResetRecipe,
  handleEditOption,
  handleShowEditOptionsModal,
}) => {
  usePageview("DesignPage");

  const {
    optionsPerStep,
    selectedStep,
    isFinalNextEnabled,
    handleSelectStep,
    handleNavButtonClick,
    handleSelectOption,
    handleNext,
    shouldPreventGoToNextOption,
    setShouldPreventGoToNextOption,
  } = useHandlers({
    steps,
    options,
    editingOptionFromSummary,
    updateOptionsHandler,
    handleSelectPage,
    handleResetRecipe,
    handleEditOption,
    handleShowEditOptionsModal,
  });

  const { currentItem, SetCurrentItem, SelectCarouselDot } = useStore();

  const handleNextPage = () => {
    return handleNext(
      !(selectedStep + 1 < steps.length || isFinalNextEnabled),
      () => handleNavButtonClick("next")
    );
  };

  const handlePrevPage = (force = false) => {
    if (selectedStep > 0 || force) handleNavButtonClick("previous");
  };

  const handleNextItemOrPage = () =>
    SelectCarouselDot(currentItem + 1, handleNextPage, handlePrevPage);

  const handlePreviousItemOrPage = () =>
    SelectCarouselDot(currentItem - 1, handleNextPage, handlePrevPage);

  return (
    <Component
      {...{
        isHidden: isHidden,
        stepsComponent: (
          <Steps
            steps={steps}
            optionsPerStep={optionsPerStep}
            selectedStep={selectedStep}
            handleSelectStep={handleSelectStep}
          />
        ),
        stepsMobileComponent: (
          <StepsMobile
            steps={steps}
            selectedStep={selectedStep}
            optionsPerStep={optionsPerStep}
            handleNavButtonClickNext={handleNextItemOrPage}
            handleNavButtonClickPrev={
              selectedStep === 0
                ? () => handlePrevPage(true)
                : handlePreviousItemOrPage
            }
            handleSelectStep={handleSelectStep}
            isNextEnabled={true}
          />
        ),
        contentProps: {
          left: (
            <Preview
              imageSrc={selectedModelType?.modelBaseImageSrc ?? ""}
              avatarImages={avatarImages}
            />
          ),
          right: (
            <Options
              currentStepKey={steps[selectedStep]?.key}
              options={options}
              isLastStep={steps.length === selectedStep + 1}
              shouldPreventGoToNextOption={shouldPreventGoToNextOption}
              setShouldPreventGoToNextOption={setShouldPreventGoToNextOption}
              editingOptionFromSummary={editingOptionFromSummary}
              handleSelectOption={handleSelectOption}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousItemOrPage}
              selectedUnit={selectedUnit}
            />
          ),
        },
        buttonProps: {
          secondaryProps: {
            handleClick:
              selectedStep === 0
                ? () => handlePrevPage(true)
                : handlePreviousItemOrPage,
          },
          primaryProps: {
            handleClick: handleNextItemOrPage,
            isDisabled: false,
          },
        },
      }}
    />
  );
};

DesignPage.propTypes = {
  isHidden: PropTypes.bool.isRequired,
  updateOptionsHandler: PropTypes.func,
  handleSelectPage: PropTypes.func,
  selectedModelType: modelTypeObj,
  steps,
  avatarImages,
  options,
};

export default DesignPage;
