import React from "react";
import PropTypes from "prop-types";
import s from "./SwatchElement.module.css";
import Thumbnail from "../Thumbnail";

const SwatchElement = ({
  size,
  visibilityType = "enabled",
  thumbnailSrc,
  name,
  helperLabel,
  isSelected = false,
  handleClick,
}) => {
  return (
    <div
      data-enabled={visibilityType === "enabled"}
      className={`${s.wrapper} ${s[visibilityType]}`}
      onClick={handleClick}
    >
      <Thumbnail size={size} isSelected={isSelected} imageSrc={thumbnailSrc} />
      <h4 className={isSelected ? s.selected : null}>{name}</h4>
      {helperLabel && helperLabel.text && (
        <p
          className={`${s.label} ${s[helperLabel.type]}`}
          dangerouslySetInnerHTML={{ __html: helperLabel.text }}
        />
      )}
    </div>
  );
};

SwatchElement.propTypes = {
  name: PropTypes.string.isRequired,
  thumbnailSrc: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  visibilityType: PropTypes.oneOf(["enabled", "disabled"]),
  size: PropTypes.string,
  helperLabel: PropTypes.shape({
    type: PropTypes.oneOf(["error", "info"]).isRequired,
    text: PropTypes.string.isRequired,
  }),
};

export default SwatchElement;
