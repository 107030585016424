import { Fab as MUIFab, withStyles } from "@material-ui/core";

const Fab = withStyles({
  root: {
    boxShadow: "0 4px 12px 0 rgba(0,0,0,0.2)",
    backgroundColor: "white",
    ["&:active"]: {
      boxShadow: "0 4px 12px 0 rgba(0,0,0,0.2)",
      backgroundColor: "white",
    },
    ["&:hover"]: {
      backgroundColor: "white",
    },
  },
})(MUIFab);

export default Fab;
